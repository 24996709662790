(function () {
    angular
        .module('app')
        .run(AuthentListener);

    /**
     * Contrôle à chaque changement d'état que la session utilisateur est authentifiée, et sinon renvoie sur la page d'authentification
     * @ngInject */
    /* eslint max-params: "off" */
    function AuthentListener($rootScope, SessionService, $state, $log, ApplicationService, ConfigService) {

        function stateChangeListener(event, toState) {
            if (!ApplicationService.isAuthentNeeded()) {
                return;
            }
            var authentStateName = ConfigService.getAuthentStateName();
            if (toState.name !== authentStateName && !SessionService.isAuthenticated()) {
                $log.info('Demande de la page \'' + toState.name + '\' alors que la session utilisateur n\'est pas authentifiée. Redirection sur \'' + authentStateName + '\'');
                event.preventDefault();
                $state.go(authentStateName);
            }
        }

        var unregister = $rootScope.$on('$stateChangeStart', stateChangeListener);

        $rootScope.$on('$destroy', function () {
            unregister();
        });

    }
})();
