(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    .controller('HeaderController', function ($scope, $log, SessionService, ClientService, ConfigService, $state) {

      var vm = this;
      var LS_KEY_TOKEN = 'token';

      vm.name = 'header';
      vm.nomClient = '';
      vm.displayCancel = false;
      vm.personne = SessionService.getValue(LS_KEY_TOKEN).personne;

         /**
       * Emission de l'évènement logout
       */
      vm.logout = function () {
        SessionService.setSessionValue('token', undefined);
        SessionService.setValue('token', undefined);
        $state.go('auth');
      };

      /**
       * Ecoute de l'évènement changeFooter pour mettre à jour l'affichage des boutons du footer
       */
      $scope.$on('showLogout', function (event, data) {
        vm.displayLogout = data.displayLogout;
      });

      ClientService.getClient(vm.personne.login)
        .then(function (response) {
          $log.log('reponse get info client : ' + response);
          vm.nomClient = response.data.nom;
        })
        .catch(function (error) {
          $log.log('erreur pendant get info client : ' + error);
        });

    });
})();
