/* global moment */
(function () {
  angular
    .module('app')
    .config(function ($mdDateLocaleProvider, $windowProvider) {
      var $window = $windowProvider.$get();
      var locale = $window.navigator.userLanguage || $window.navigator.language;

      // Example of a French localization.
      $mdDateLocaleProvider.months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
      $mdDateLocaleProvider.shortMonths = ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'];
      $mdDateLocaleProvider.days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
      $mdDateLocaleProvider.shortDays = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

      // Can change week display to start on Monday.
      $mdDateLocaleProvider.firstDayOfWeek = 1;

      // Example uses moment.js to parse and format dates.
      $mdDateLocaleProvider.parseDate = function (dateString) {
        var m = moment(dateString, 'DD/MM/YYYY', true);
        m.locale(locale);
        return m.isValid() ? m.toDate() : new Date(NaN);
      };

      $mdDateLocaleProvider.formatDate = function (date) {
        var m = moment(date);
        m.locale(locale);
        return m.isValid() ? m.format('DD/MM/YYYY') : '';
      };

      $mdDateLocaleProvider.monthHeaderFormatter = function (date) {
        return this.shortMonths[date.getMonth()] + ' ' + date.getFullYear();
      };

      // In addition to date display, date components also need localized messages
      // for aria-labels for screen-reader users.

      $mdDateLocaleProvider.weekNumberFormatter = function (weekNumber) {
        return 'Semaine ' + weekNumber;
      };

      $mdDateLocaleProvider.msgCalendar = 'Calendrier';
      $mdDateLocaleProvider.msgOpenCalendar = 'Ouvrir le calendrier';
    });
})();

