(function () {
  /**
   * Configuration générale à l'application.
   * @constructor
   */

  function AdvConfigService($location) {

    var that = this;

    // Contient les paramètres de configuration
    that.params = {};

    that.authentStateName = 'auth';

    // numéro de version
    that.params.numeroVersion = '3.0.0';

    that.getAuthentStateName = function () {
      return that.authentStateName;
    };

    that.setAuthentStateName = function (value) {
      that.authentStateName = value;
    };

    that.params.backendUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port();

    that.getBackendUrl = function () {
      return that.params.backendUrl;
    };

    that.setBackendUrl = function (value) {
      that.params.backendUrl = value;
    };

    that.getNumeroVersion = function () {
      return that.params.numeroVersion;
    };

    that.mergeConfig = function (newParams) {
      if (newParams !== null && angular.isObject(newParams)) {
        for (var key in newParams) {
          if (Object.prototype.hasOwnProperty.call(newParams, key)) {
            that.params[key] = newParams[key];
          }
        }
      }
    };

  }

  angular
    .module('app').service('AdvConfigService', AdvConfigService);
})();
