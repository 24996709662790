(function () {
  /**
   * Service métier de gestion des especes
   * @constructor
   */

  angular
    .module('app').factory('EspeceService', EspeceService);

  /* eslint max-params: "off" */
  function EspeceService($log, ConfigService, $http) {

    var that = this;

    /**
     * requête pour récupérer la liste des espèces
     * @returns {*}
     */
    that.getEspeces = function () {
      var url = ConfigService.getBackendUrl() + 'espece/';
      $log.log('Demande de recuperation des especes ');
      return $http.get(url);
    };

    /**
     * requête pour récupérer la liste des types d'especes
     * @returns {*}
     */
    that.getTypesEspece = function () {
      var url = ConfigService.getBackendUrl() + 'espece/type';
      $log.log('Demande de recuperation des types d\'especes ');
      return $http.get(url);
    };

    /**
     * requête pour récupérer la liste des types d'especes
     * @returns {*}
     */
    that.getInfoRoutage = function () {
      var url = ConfigService.getBackendUrl() + 'espece/routage';
      $log.log('Demande de recuperation des infos article du routage ');
      return $http.get(url);
    };

    return that;
  }
})();
