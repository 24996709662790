(function () {
    angular
        .module('app')
        .service('AuthService', AuthService);
    /**
     * Gère le métier spécifique au use case auth qui n'est pas commun à l'application et donc qui n'est pas dans AuthentService
     * @constructor
     */
    function AuthService(AuthentService) {

        this.authenticateQ = function (userName, password) {
            return AuthentService.authenticateQ(userName, password);
        };

    }

})();
