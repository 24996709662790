(function () {
  angular
    .module('app')
    .run(PlatformConfigReader);

  /**
   * Met à jour la configuration avec les données de la plateforme si celle-ci définit un fichier config/platform-config.json
   *
   * @ngInject */
  /* eslint max-params: off */
  function PlatformConfigReader($rootScope, ApplicationService, ConfigService, $http, $log, $timeout) {
    var manageConfig = function (platformConfig) {
      $log.log('Prise en compte des données de configuration de la plateforme : ' + angular.toJson(platformConfig));
      ConfigService.mergeConfig(platformConfig);
      $timeout(function () {
        $log.log('broadcast configLoaded');
        $rootScope.$broadcast('configLoaded', {
          config: true
        });
      }, 1000);
    };


    $http.get('config/platform-config.json')
      .then(function (config) {
        manageConfig(config.data);
      })
      .catch(function () {
        $log.info('Pas de récupération du fichier config/platform-config.json. Utilisation de la configuration par défaut');
      })
      .finally(function () {
        ApplicationService.setReady();
      });

  }
})();
