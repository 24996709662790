(function () {
    /**
     * Session utilisateur générale à l'application.
     * @constructor
     */

    function AdvSessionService() {

        var that = this;

        that.authenticated = false;

        that.isAuthenticated = function () {
            return that.authenticated;
        };

        that.setAuthenticated = function (value) {
            that.authenticated = value;
        };

    }

    angular
        .module('app').service('AdvSessionService', AdvSessionService);
})();
