(function () {
  angular
    .module('app')
    .controller('FooterController', function ($rootScope, $scope, $log) {

      var vm = this;

      vm.name = 'footer';
      vm.displayBack = true;
      vm.displayMenu = false;
      vm.displayNext = true;
      vm.displayExit = false;

      /**
       * Ecoute de l'évènement changeFooter pour mettre à jour l'affichage des boutons du footer
       */
      $scope.$on('changeFooter', function (event, data) {
        $log.log('event changeFooter received !! ' + angular.toJson(data));
        vm.displayBack = data.displayBack;
        // vm.displayMenu = data.displayMenu;
        vm.displayNext = data.displayNext;
        vm.displayExit = data.displayExit;
      });

      /**
       * Emission de l'évènement goToBack sur clic de back
       */
      vm.gotToBack = function () {
        $log.log('Emit event goToBack');
        $rootScope.$broadcast('goToBack', {
          back: true
        });
      };

      /**
       * Emission de l'évènement goToNext sur clic de next
       */
      vm.gotToMenu = function () {
        $log.log('Emit event goToMenu');
        $rootScope.$broadcast('goToMenu', {
          menu: true
        });
      };

      /**
       * Emission de l'évènement goToNext sur clic de next
       */
      vm.gotToNext = function () {
        $log.log('Emit event goToNext');
        $rootScope.$broadcast('goToNext', {
          next: true
        });
      };

    });
})();
