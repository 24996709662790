(function () {
    angular
        .module('app')
        .service('AdresseService', AdresseService);
    /**
     * Gère le métier spécifique à l'adresse
     * @constructor
     */
    function AdresseService(ClientService) {

        this.getAddresses = function (userId) {
            return ClientService.getAddresses(userId);
        };

    }

})();
