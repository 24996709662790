(function () {
    /**
     * Service de gestion des erreurs
     * @constructor
     */

    function AdvErrorService($log) {

        var that = this;

        that.onError = function (error) {
            $log.error('Erreur : ' + error);
        };

    }

    angular
        .module('app').service('AdvErrorService', AdvErrorService);
})();
