(function () {
  /**
   *  @name : Le module angular représentant l'application
   */
  /* eslint max-params: "off" */
  angular
    .module('app', ['ui.router', 'pascalprecht.translate', 'ngMaterial', 'material.core', 'ngMdIcons', 'md.data.table', 'ngMessages'])
    .config(function ($mdThemingProvider, $httpProvider, $mdAriaProvider) {
      $mdThemingProvider.theme('default')
        .primaryPalette('green')
        .accentPalette('green');
      $httpProvider.interceptors.push('HttpInterceptor');
      $mdAriaProvider.disableWarnings();
    });
})();

