(function () {
  /**
   * Service métier de gestion de la session utilisateur
   * @constructor
   */

  angular
    .module('app').factory('SessionService', SessionService);

  function SessionService(AdvSessionService) {

    // ce service hérite du service AdvSessionService
    var that = angular.extend(AdvSessionService, {});
    // ici poser les méthodes sur that pour enrichir le service

    that.infoConnexion = null;
    that.setSessionValue = setSessionValue;
    that.getSessionValue = getSessionValue;
    that.infos = null;
    that.setValue = setValue;
    that.getValue = getValue;

    that.setConnecte = function (estConnectee, infoConnexion) {
      that.setAuthenticated(estConnectee);
      that.infoConnexion = infoConnexion;
    };

    that.getInfoConnexion = function () {
      return that.infoConnexion;
    };

    function setSessionValue(key, value) {
      if (!that.infoConnexion) {
        that.infoConnexion = {};
      }
      that.infoConnexion[key] = value;
    }

    function getSessionValue(key) {
      if (!that.infoConnexion) {
        return null;
      }
      return that.infoConnexion[key];
    }

    function setValue(key, value) {
      if (!that.infos) {
        that.infos = {};
      }
      that.infos[key] = value;
    }

    function getValue(key) {
      if (!that.infos) {
        return null;
      }
      return that.infos[key];
    }

    return that;
  }

})();
