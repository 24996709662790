(function () {
  /**
   * Service métier d'authentification
   * @constructor
   */

  angular
    .module('app').factory('AuthentService', AuthentService);

  /* eslint max-params: "off" */
  function AuthentService(AdvAuthentService, $q, SessionService, $log, ConfigService, $http) {

    // Ce service hérite du service AdvAuthentService
    var that = angular.extend(AdvAuthentService, {});

    const LS_KEY_TOKEN = 'token';

    /**
     * requête d'authentification
     * @param userName
     * @param password
     * @returns {*}
     */
    that.authenticateQ = function (userName, password) {
      var resultQ = $q.defer();
      $log.log('Demande d authentification');
      var url = ConfigService.getBackendUrl() + 'login/';

      // début de l'authentification classique
      $http.post(url, {
        login: userName,
        password: password
      }).then(function (result) {
        if (angular.isObject(result.data) && angular.isDefined(result.data.jeton)) {
          // enregistrement de result.data dans le local storage
          result.data.personne.login = userName;
          var loginData = {};
          angular.copy(result.data, loginData);
          SessionService.setConnecte(true, loginData);
          SessionService.setSessionValue(LS_KEY_TOKEN, result.data);
          SessionService.setValue(LS_KEY_TOKEN, result.data);
          $log.log('Authentification acceptée par le serveur distant');
          resultQ.resolve(result.data.personne);
        } else {
          $log.log('Authentification refusée par le serveur : error');
          resultQ.reject(result);
        }
      }, function (error) {
        $log.log('Authentification refusée par le serveur : error');
        // ErrorService.onError("Echec de l'authentification serveur : " + error);
        resultQ.reject(error);
      }).finally(function () {
        // dans tous les cas il faut dire qu'on n'est plus en authentification
        that.authenticating = false;
      });
      return resultQ.promise;
    };

    return that;
  }
})();
