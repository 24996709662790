(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    .controller('ContactServiceController', function ($scope, $rootScope, $log, $state, $document, $filter, $mdDialog, SessionService) {

      var vm = this;

      /**
       * Initialisation du controleur
       */
      vm.init = function () {
        // variables
        vm.address = SessionService.getSessionValue('currentAddress');
        vm.client = SessionService.getSessionValue('client');
        vm.conf = SessionService.getSessionValue('conf');
        vm.oldAddress = {numTel: vm.address.numTel, numPort: vm.address.numPort};
        vm.oldClient = {email: vm.client.email, aboSms: vm.client.aboSms, aboMail: vm.client.aboMail};
        vm.formValid = true;
        vm.noPhone = false;
        vm.emailEmpty = false;

        if (String(vm.address.numTel) === 'undefined') {
          vm.address.numTel = '';
        }
        if (angular.isUndefined(vm.address.numPort)) {
          vm.address.numPort = '';
        }
        if (angular.isUndefined(vm.client.email)) {
          vm.client.email = '';
        }
        // désactivation de l'abonnement SMS par défaut si aucun n° de téléphone n'est préalablement renseigné
        if (vm.address.numTel === '' && vm.address.numPort === '') {
          vm.client.aboSms = false;
        }
        // désactivation de l'abonnement mail si l'adresse n'est pas préalablement renseignée
        if (vm.address.email === '') {
          vm.client.email = false;
        }

        // fonctions
        vm.phoneFieldChange = phoneFieldChange;
        vm.emailFieldChange = emailFieldChange;
        vm.goToDemande = goToDemande;
        vm.backToAddress = backToAddress;
        vm.backToArticle = backToArticle;

        // watcher sur état de validation du formulaire
        $scope.$watch('contactForm.$valid', function (newVal) {
          vm.formValid = newVal;
        });
      };

      /**
       * si les champs telephone sont vides, on desactive automatiquement l'abonnement SMS
       */
      function phoneFieldChange() {
        if (String(vm.address.numTel) === 'undefined') {
          if (vm.address.numPort.length === 0) {
            vm.client.aboSms = false;
          }
        } else if (String(vm.address.numPort) === 'undefined') {
          if (vm.address.numTel.length === 0) {
            vm.client.aboSms = false;
          }
        } else if (vm.address.numTel.length === 0 && vm.address.numPort.length === 0) {
          vm.client.aboSms = false;
        }
      }

      /**
       * si le champ email est vide, on desactive automatiquement l'abonnement email
       */
      function emailFieldChange() {
        if (angular.isDefined(vm.client.email)) {
          if (vm.client.email.length === 0) {
            vm.client.aboMail = false;
          }
        }
      }

      /**
       * aller à la page de fin de demande
       */
      function goToDemande() {
        vm.emailEmpty = false;
        vm.noPhone = false;
        if (vm.client.email === '') {
          vm.emailEmpty = true;
          if (vm.address.numTel === '' && vm.address.numPort === '') {
            vm.noPhone = true;
          }
        } else if (vm.address.numTel === '' && vm.address.numPort === '') {
          vm.noPhone = true;
        } else {
          if (angular.isUndefined(SessionService.getSessionValue('clientInfoUpdate')) ||
              SessionService.getSessionValue('clientInfoUpdate') === false) {
            SessionService.setSessionValue('currentAddress', vm.address);
            SessionService.setSessionValue('client', vm.client);
            if (vm.address.numTel !== vm.oldAddress.numTel || vm.address.numPort !== vm.oldAddress.numPort ||
              vm.client.email !== vm.oldClient.email || vm.client.aboSms !== vm.oldClient.aboSms ||
              vm.client.aboMail !== vm.oldClient.aboMail) {
              SessionService.setSessionValue('clientInfoUpdate', true);
            } else {
              SessionService.setSessionValue('clientInfoUpdate', false);
            }
          }
          $state.go('demande');
        }
      }

      /**
       * retour à la page adresse
       */
      function backToAddress() {
        // ENL3-RG05
        SessionService.setSessionValue('currentAddress', vm.address);
        SessionService.setSessionValue('client', vm.client);
        $state.go('adresse');
      }

      /**
       * retour à la page article
       *
       * @param web - vrai si WEB - faux si mobile
       */
      function backToArticle(web) {
        // ENL3-RG05
        SessionService.setSessionValue('currentAddress', vm.address);
        SessionService.setSessionValue('client', vm.client);

        if (web) {
          $state.go('article');
        } else {
          $state.go('articleMobile');
        }
      }

      /**
       * appui sur Next
       */
      $scope.$on('goToNext', function (event, data) {
        var translate = $filter('translate');
        if (vm.client.email === '') {
          $mdDialog.show({
            templateUrl: 'app/_common/template/alert.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            multiple: true,
            locals: {
              messages: [translate('ERR017')]
            },
            controller: DialogController
          });
        } else if (vm.address.numTel === '' && vm.address.numPort === '') {
          $mdDialog.show({
            templateUrl: 'app/_common/template/alert.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            multiple: true,
            locals: {
              messages: [translate('ERR015')]
            },
            controller: DialogController
          });
        } else if (vm.formValid) {
          $log.log('event next received !! ' + data);
          SessionService.setSessionValue('currentAddress', vm.address);
          SessionService.setSessionValue('client', vm.client);
          if (vm.address.numTel !== vm.oldAddress.numTel || vm.address.numPort !== vm.oldAddress.numPort ||
            vm.client.email !== vm.oldClient.email || vm.client.aboSms !== vm.oldClient.aboSms ||
            vm.client.aboMail !== vm.oldClient.aboMail) {
            SessionService.setSessionValue('clientInfoUpdate', true);
          } else {
            SessionService.setSessionValue('clientInfoUpdate', false);
          }
          $rootScope.$broadcast('changeFooter', {
            displayBack: true,
            displayMenu: true,
            displayNext: false,
            displayExit: false
          });
          $state.go('demandeMobile');
        } else {
          $mdDialog.show({
            templateUrl: 'app/_common/template/alert.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            multiple: true,
            locals: {
              messages: [translate('ERR012')]
            },
            controller: DialogController
          });
        }
      });

      /**
       * appui sur Back
       */
      $scope.$on('goToBack', function (event, data) {
        $log.log('event back received !! ' + data);

        $rootScope.$broadcast('changeFooter', {
          displayBack: true,
          displayMenu: true,
          displayNext: false,
          displayExit: false
        });
        vm.backToArticle(false);
      });

      /**
       * Controleur pour la modale d'erreur
       *
       * @param $scope
       * @param $mdDialog
       * @constructor
       */
      function DialogController($scope, $mdDialog, messages) {
        var that = $scope;
        that.messages = messages;
        that.title = null;
        that.ok = null;
        that.closeDialog = function () {
          $mdDialog.hide();
        };
      }

      vm.init();

    });
})();
