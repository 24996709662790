(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    .controller('ArticleController', function ($rootScope, $scope, $log, $state, $translate, $filter, $mdDialog, $document, ArticleUtils, SessionService, ArticleService) {

        var vm = this;

        // etat initial
        vm.message = '';
        vm.articleUtils = ArticleUtils;
        vm.articleState = 'addType';
        vm.category = {codeArticle: '', lstAnimaux: [], quantite: ''};
        vm.articles = [];
        vm.currentOrder = {};
        vm.typeSel = '';
        vm.animals = [];
        vm.categories = [];
        vm.currentAnimal = {codePays: 'FR', ipg: '', sexe: '', typeLV: '', quantiteLot: 1};
        vm.additionnalFields = {};
        vm.number = 0;
        vm.uniqCheck = [];
        vm.speciesClass = '';
        vm.addArticleClass = 'add-article-disabled';
        vm.typeEditCode = '';
        vm.ipgListControl = [];
        vm.editIndex = 0;
        vm.editOldArticle = undefined;
        vm.nombreIdentification = 1;

        // si présence en session, récupération des données de la demande
        if (SessionService.getSessionValue('currentOrder') && SessionService.getSessionValue('currentOrder') !== null) {
          $log.log('old order');
          vm.currentOrder = SessionService.getSessionValue('currentOrder');
          vm.articles = vm.currentOrder.lstArticle;
          if (vm.articles.length > 0) {
            vm.articleState = 'list';
          }
        }
        vm.address = SessionService.getSessionValue('currentAddress');
        vm.oldAddress = {idmPorcin: vm.address.idmPorcin, numDetenteur: vm.address.numDetenteur};

        // tableau des valeurs de 'quantités' possibles - IHM Mobile
        vm.quantities = [];
        vm.quantityTitle = '';

        /**
         * filtre sur les especes
         */
        function filterSpecies(species) {
          return species.codeType === vm.typeSel.code;
        }

        function filterEditSpecies(species) {
          return species.codeType === vm.typeEditCode;
        }

        /**
         * Changer l'affichage des boutons du footer mobile
         *
         * @param back - bouton back
         * @param menu - bouton menu
         * @param next - bouton next
         */
        function changeFooter(back, menu, next) {
          $rootScope.$broadcast('changeFooter', {
            displayBack: back,
            displayMenu: menu,
            displayNext: next,
            displayExit: false
          });
        }

        /**
         * sauvegarde d'un article dans la liste
         */
        function saveArticle() {
          if (vm.typeEditCode === '') {
            vm.articles.push(angular.copy(vm.category));
          } else {
            vm.articles.splice(vm.editIndex, 1, angular.copy(vm.category));
          }
          vm.category = {
            codeArticle: '',
            lstAnimaux: [],
            quantite: ''
          };
          vm.typeEditCode = '';
          vm.editIndex = 0;
          changeFooter(true, true, false);
          vm.articleState = 'list';
        }

        /**
         * Afficher une modale pour les erreurs
         *
         * @param message
         */
        function displayWarningAlert(messages) {
          $mdDialog.show({
            templateUrl: 'app/_common/template/alert.dialog.html',
            ok: translateMsg('ADV091', null),
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            locals: {
              messages: messages
            },
            multiple: true,
            controller: DialogController
          });
          function DialogController($scope, $mdDialog) {
            var that = $scope;
            that.messages = messages;
            that.title = null;
            that.ok = null;
            that.closeDialog = function () {
              vm.message = '';
              $mdDialog.hide();
            };
          }
        }

        /**
         * aller vers l'écran détails, préparation des données pour l'écran
         */
        function goToDetails() {
          vm.animalIndex = 1;

          if (vm.typeEditCode === '') {
            vm.category.lstAnimaux = [];
          } else {
            vm.currentAnimal = vm.category.lstAnimaux[vm.animalIndex - 1];
          }

          if (vm.category.unite === 'L') {
            if (vm.typeEditCode === '') {
              vm.nombreIdentification = 1;
            } else {
              vm.nombreIdentification = vm.category.lstAnimaux.length;
            }
          } else {
            vm.nombreIdentification = vm.category.quantite;
          }
          vm.additionnalFields = ArticleUtils.getAdditionnalFields(vm.category);
          changeFooter(true, true, true);
          vm.articleState = 'setDetails';
        }

        /**
         * recherche du message dans le bundle
         *
         * @param bundleKey - clé du message
         * @param category - espèce
         * @return message traduit du bundle
         */
        function translateMsg(bundleKey, category) {
          var translate = $filter('translate');
          var message = '';
          if (bundleKey === 'MSG008') {
            if (category.minIPG === category.maxIPG) {
              message = translate('ERR014', {min: category.minIPG});
            } else {
              message = translate(bundleKey, {min: category.minIPG, max: category.maxIPG});
            }
          } else {
            message = translate(bundleKey);
          }
          return message;
        }

        /**
         * Add article from routing (when comming from Routage module)
         *
         * @param artRoutage
         */
        function addArticleFromRouting(artRoutage) {
          for (var is = 0; is < vm.articleSpecies.length; is++) {
            if (artRoutage.codeArticle === vm.articleSpecies[is].codeArticle) {
              var art = vm.articleSpecies[is];
              art.quantite = artRoutage.quantite;
              art.lstAnimaux = artRoutage.lstAnimaux;
              var addFields = ArticleUtils.getAdditionnalFields(art);
              if (angular.isDefined(addFields.ipgCodeLabel)) {
                for (var ian = 0; ian < art.lstAnimaux.length; ian++) {
                  if (angular.isDefined(art.lstAnimaux[ian].ipg)) {
                    art.lstAnimaux[ian].codePays = art.lstAnimaux[ian].ipg.substr(0, 2);
                    art.lstAnimaux[ian].ipg = art.lstAnimaux[ian].ipg.substr(2);
                  }
                }
              }
              vm.articles.push(art);
            }
          }
          if (vm.articles.length > 0) {
            vm.articleState = 'list';
          }
        }

        /**
         * recupérer le type d'espèce pour un article
         *
         * @param article
         * @return {*}
         */
        function getTypeForArticle(article) {
          for (var it = 0; it < vm.articleTypes.length; it++) {
            if (article.codeType === vm.articleTypes[it].code) {
              return vm.articleTypes[it];
            }
          }
        }

        /**
         * changement de la valeur de la combo espece (WEB)
         */
        vm.speciesSelectedWeb = function () {
          if (vm.typeSel === {}) {
            vm.addArticleClass = 'add-article-disabled';
          } else {
            vm.addArticleClass = '';
          }
          vm.showNewArticles();
        };
        vm.speciesClickWeb = function (type) {
          vm.typeSel = type;
          if (vm.typeSel === {}) {
            vm.addArticleClass = 'add-article-disabled';
          } else {
            vm.addArticleClass = '';
          }
          vm.showNewArticles();
        };

        /**
         * Afficher la modale 'ajouter article'
         */
        vm.showNewArticles = function () {
          var species = [];
          angular.copy(vm.articleSpecies.filter(filterSpecies), species);
          for (var ia = 0; ia < vm.articles.length; ia++) {
            for (var is = 0; is < species.length; is++) {
              if (species[is].codeArticle === vm.articles[ia].codeArticle) {
                species.splice(is, 1);
                break;
              }
            }
          }
          if (vm.typeSel !== '') {
            $mdDialog.show({
              controller: ArticleDialogController,
              templateUrl: 'app/article/newarticle.dialog.html',
              parent: angular.element($document.body),
              scope: $scope,
              preserveScope: true,
              clickOutsideToClose: true,
              locals: {
                typeSel: vm.typeSel,
                categories: species,
                edit: false,
                article: null,
                articles: vm.articles,
                index: null,
                isModif: false
              },
              multiple: true,
              fullscreen: true // Only for -xs, -sm breakpoints.
            });
          }
        };

        /**
         * recupere la liste des types d'especes depuis le serveur
         */
        vm.getTypes = function () {
          vm.typeEditCode = '';
          ArticleService.getTypesEspece()
            .then(function (response) {
              vm.articleTypes = response.data;
              for (var iat = 0; iat < vm.articleTypes.length; iat++) {
                vm.articleTypes[iat].picture = '';
                if (vm.articleTypes[iat].code === '1') {
                  vm.articleTypes[iat].picture = 'images/btn_bovin.png';
                } else if (vm.articleTypes[iat].code === '7') {
                  vm.articleTypes[iat].picture = 'images/btn_caprin.png';
                } else if (vm.articleTypes[iat].code === '4') {
                  vm.articleTypes[iat].picture = 'images/btn_equide.png';
                } else if (vm.articleTypes[iat].code === '2') {
                  vm.articleTypes[iat].picture = 'images/btn_ovin.png';
                } else if (vm.articleTypes[iat].code === '3') {
                  vm.articleTypes[iat].picture = 'images/btn_porc.png';
                } else if (vm.articleTypes[iat].code === '5') {
                  vm.articleTypes[iat].picture = 'images/btn_volaille-lapin.png';
                }
              }
            })
            .catch(function (error) {
              $log.log('Get species types error : ' + error);
              return [];
            });
        };
        vm.articleTypes = [vm.getTypes()];

        /**
         * recupere la liste des especes depuis le serveur
         */
        vm.getSpecies = function () {
          ArticleService.getEspeces()
            .then(function (response) {
              vm.articleSpecies = response.data;
              // recopie d'un champ pour faciliter les manipulation futures (ajout / modif articles)
              for (var i = 0; i < vm.articleSpecies.length; i++) {
                vm.articleSpecies[i].codeArticle = vm.articleSpecies[i].code;
              }
              if (angular.isDefined(SessionService.getSessionValue('articlesRoutage'))) {
                var artRoutage = SessionService.getSessionValue('articlesRoutage');
                if (artRoutage.length > 0) {
                  $log.log('Articles issus du routage : ' + artRoutage.length);
                  for (var ia = 0; ia < artRoutage.length; ia++) {
                    addArticleFromRouting(artRoutage[ia]);
                  }
                }
              }
              if (vm.articles.length > 0) {
                for (var ina = 0; ina < vm.articles.length; ina++) {
                  for (var ins = 0; ins < vm.articleSpecies.length; ins++) {
                    if (vm.articles[ina].codeArticle === vm.articleSpecies[ins].codeArticle) {
                      vm.articles[ina].codeType = vm.articleSpecies[ins].codeType;
                      vm.articles[ina].saisieIpg = vm.articleSpecies[ins].saisieIpg;
                      vm.articles[ina].saisieSexe = vm.articleSpecies[ins].saisieSexe;
                      vm.articles[ina].saisieTypElv = vm.articleSpecies[ins].saisieTypElv;
                    }
                  }
                }
              }
              SessionService.setSessionValue('articlesRoutage', []);
            })
            .catch(function (error) {
              $log.log('Get species error : ' + error);
              return [];
            });
        };
        vm.articleSpecies = [vm.getSpecies()];

        /**
         * Etat 'ajout d'un article'
         */
        vm.addArticle = function () {
          vm.articleState = 'addType';
        };

        /**
         * Etat 'type espèce sélectionné'
         */
        vm.typeSelected = function (type) {
          // ENL2-RG01
          vm.typeSel = type;
          // ENL2-RG05
          angular.copy(vm.articleSpecies.filter(filterSpecies), vm.categories);
          for (var ia = 0; ia < vm.articles.length; ia++) {
            for (var is = 0; is < vm.categories.length; is++) {
              if (vm.categories[is].codeArticle === vm.articles[ia].codeArticle) {
                vm.categories.splice(is, 1);
                break;
              }
            }
          }
          vm.articleState = 'setSpecies';
        };

        /**
         * Etat 'espèce sélectionnée'
         *
         * @param category
         * @param validState
         */
        vm.speciesSelected = function (category, validState) {
          if (validState) {
            // ENL2-RG01
            vm.category = category;
            if (vm.category.codeType === '3' || vm.category.codeType === '2' ||
              vm.category.codeType === '5' || vm.category.codeType === '6' || vm.category.codeType === '7') {
              // Pour le type porc, ovin, volaille - lapin, autre ou caprin
              if (vm.category.unite === 'Q') {
                // quantité
                vm.quantityTitle = translateMsg('ADV057', null);
                vm.quantities = [1, 2, 3, 4, 5, 6, 10, 'AUTRE'];
                vm.articleState = 'setQuantity';
              } else if (vm.category.unite === 'P') {
                // poids
                vm.quantities = [100, 150, 200, 250, 300, 400, 600, 'AUTRE'];
                vm.articleState = 'setWeight';
              } else if (vm.category.unite === 'L') {
                // lot
                vm.quantityTitle = translateMsg('ADV059', null);
                vm.quantities = [1, 2, 3, 4, 5, 6, 10, 'AUTRE'];
                vm.articleState = 'setBundle';
                vm.currentAnimal = {ipg: '', sexe: '', typeLV: '', quantiteLot: 1};
              } else {
                // Pour le reste
                goToDetails();
              }
            } else if (vm.category.codeType === '1' || vm.category.codeType === '4') {
              vm.quantityTitle = translateMsg('ADV057', null);
              vm.quantities = [1, 2, 3, 4, 5, 6, 10, 'AUTRE'];
              vm.articleState = 'setQuantity';
            } else {
              // Pour le reste
              goToDetails();
            }
          } else {
            var errorMsg = translateMsg('ERR010', null);
            if (category.codeType === '3') {
              errorMsg = translateMsg('ERR011', null);
            }
            displayWarningAlert([errorMsg]);
          }
        };

        /**
         * Etat 'Quantité entrée'
         *
         * @param quantity
         */
        vm.setQuantity = function (quantity) {
          if (quantity === 'AUTRE') {
            changeFooter(true, true, false);
            vm.articleState = 'setOtherNumber';
          } else {
            var articleToCheck = ArticleUtils.initCheckAnimal(vm.address, vm.category, vm.currentAnimal);
            articleToCheck.lstArticle[0].quantite = quantity;
            articleToCheck.lstArticle[0].lstAnimaux = [];
            // ENL2-RG06
            ArticleService.checkArticle(articleToCheck).then(function (response) {
              $log.log('Nombre OK : ' + response);
              vm.category.quantite = quantity;
              if (vm.category.codeType === '2' || vm.category.codeType === '7') {
                // pour les types ovin ou caprin
                if (vm.category.unite === 'Q' || vm.category.unite === 'L') {
                  // lots
                  goToDetails();
                } else {
                  vm.category.lstAnimaux = [];
                  saveArticle();
                }
              } else if (vm.category.saisieIpg === 'Non') {
                  vm.category.lstAnimaux = [];
                  saveArticle();
                } else {
                  goToDetails();
                }
            })
              .catch(function (error) {
                var errorMsg = [];
                for (var i = 0; i < error.data.length; i++) {
                  errorMsg.push(error.data[i].message);
                }
                displayWarningAlert(errorMsg);
              });
          }
        };

        /**
         * vérifie une quantité autre, et passe à la suite
         */
        vm.checkOtherNumber = function () {
          var articleToCheck = ArticleUtils.initCheckAnimal(vm.address, vm.category, vm.currentAnimal);
          articleToCheck.lstArticle[0].quantite = vm.number;
          articleToCheck.lstArticle[0].lstAnimaux = [];
          // ENL2-RG06
          ArticleService.checkArticle(articleToCheck).then(function (response) {
            $log.log('Nombre OK : ' + response);
            vm.setQuantity(vm.number);
          })
            .catch(function (error) {
              var errorMsg = [];
              for (var i = 0; i < error.data.length; i++) {
                if (error.data[i].bloquant) {
                  errorMsg.push(error.data[i].message);
                }
              }
              displayWarningAlert(errorMsg);
            });
        };

        /**
         * Etat 'détail animal entré'
         */
        vm.setDetails = function () {
          // fait vérifier le dernier article par le serveur
          var articleToCheck = ArticleUtils.initCheckAnimal(vm.address, vm.category, vm.currentAnimal);
          ArticleService.checkArticle(articleToCheck).then(function (response) {
            $log.log('Animal Article OK : ' + response);

            /**
             * Identifie le nombre de page de lot à afficher
             * @type {*|T}
             */
            if (vm.typeEditCode === '') {
              vm.category.lstAnimaux.push(vm.currentAnimal);
              if (vm.category.unite === 'L') {
                // Si on est en création pour les lot on ne creer qu'un lot quel que soit la quantité
                vm.currentAnimal = {ipg: '', sexe: '', typeLV: '', quantiteLot: vm.category.quantite};
                vm.nombreIdentification = 1;
              } else {
                vm.currentAnimal = {codePays: 'FR', ipg: '', sexe: '', typeLV: '', quantiteLot: 1};
                vm.nombreIdentification = vm.category.quantite;
              }
            } else {
              vm.category.lstAnimaux.splice(vm.animalIndex - 1, 1, vm.currentAnimal);
              vm.currentAnimal = vm.category.lstAnimaux[vm.animalIndex];

              // Dans le cas ou l'on cahnge la quantité
              if (typeof vm.currentAnimal === 'undefined') {
                if (vm.category.unite === 'L') {
                  // Si on est en création pour les lot on ne creer qu'un lot quel que soit la quantité
                  vm.currentAnimal = {ipg: '', sexe: '', typeLV: '', quantiteLot: vm.category.quantite};
                } else {
                  vm.currentAnimal = {codePays: 'FR', ipg: '', sexe: '', typeLV: '', quantiteLot: 1};
                }
              }

              if (vm.category.unite === 'L') {
                vm.nombreIdentification = vm.category.lstAnimaux.length;
              } else {
                vm.nombreIdentification = vm.category.quantite;
              }

            }
            if (vm.animalIndex === vm.nombreIdentification) {
              // construit et fait vérifier par le serveur la totalité de la demande
              articleToCheck = ArticleUtils.initCheckArticle(vm.address, vm.category);
              ArticleService.checkArticle(articleToCheck).then(function (responseArt) {
                $log.log('Article complet OK : ' + responseArt);
                var articleToAdd = {};
                angular.copy(vm.category, articleToAdd);
                if (vm.typeEditCode === '') {
                  vm.articles.push(articleToAdd);
                } else {
                  vm.articles.splice(vm.editIndex, 1, articleToAdd);
                }
                vm.category = {
                  codeArticle: '',
                  lstAnimaux: [],
                  quantite: ''
                };
                vm.typeEditCode = '';
                vm.editOldArticle = undefined;
                vm.editIndex = 0;
                changeFooter(true, true, false);
                vm.articleState = 'list';
              })
                .catch(function (error) {
                  var errorMsg = [];
                  for (var i = 0; i < error.data.length; i++) {
                    errorMsg.push(error.data[i].message);
                  }
                  displayWarningAlert(errorMsg);
                });
            } else {
              vm.animalIndex++;
              vm.articleState = 'setDetails';
            }
          })
            .catch(function (error) {
              var errorMsg = [];
              for (var i = 0; i < error.data.length; i++) {
                errorMsg.push(error.data[i].message);
              }
              displayWarningAlert(errorMsg);
            });
        };

        /**
         * Editer un article depuis le mobile
         *
         * @param article
         * @param index
         */
        vm.editArticleMobile = function (article, index) {
          vm.animalIndex = 0;
          vm.editIndex = index;
          vm.typeEditCode = article.codeType;
          vm.editOldArticle = article;
          var categories = vm.articleSpecies.filter(filterEditSpecies);
          for (var ic = 0; ic < categories.length; ic++) {
            if (categories[ic].codeArticle === article.codeArticle) {
              vm.category = angular.copy(categories[ic]);
              vm.category.quantite = article.quantite;
              vm.category.lstAnimaux = [];
              for (var ia = 0; ia < article.lstAnimaux.length; ia++) {
                vm.category.lstAnimaux.push(article.lstAnimaux[ia]);
              }
            }
          }

          if (vm.category.unite === 'L') {
            goToDetails();
            vm.setQuantity(vm.category.quantite);
          } else {
            vm.speciesSelected(vm.category, true);
          }
        };

        /**
         * Editer un article
         *
         * @param article
         * @param index
         */
        vm.editArticle = function (article, index) {
          vm.typeEditCode = article.codeType;
          $log.log('article selected');
          $mdDialog.show({
            controller: ArticleDialogController,
            templateUrl: 'app/article/newarticle.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            locals: {
              typeSel: getTypeForArticle(article),
              categories: vm.articleSpecies.filter(filterEditSpecies),
              edit: false,
              article: article,
              articles: vm.articles,
              index: index,
              isModif: true
            },
            multiple: true,
            fullscreen: true
          });
        };

        /**
         * supprimer un article de la liste
         *
         * @param index
         */
        vm.removeArticle = function (index) {
          $mdDialog.show({
            title: translateMsg('ERR007', null),
            templateUrl: 'app/_common/template/confirm.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            multiple: true,
            controller: DialogController
          });
          function DialogController($scope, $mdDialog) {
            var that = $scope;
            that.messages = [translateMsg('ADV105', null)];
            that.cancel = function () {
              $mdDialog.cancel();
            };
            that.confirm = function () {
              var artRemove = vm.articles[index];
              for (var iar = 0; iar < artRemove.lstAnimaux.length; iar++) {
                for (var icr = 0; icr < vm.ipgListControl.length; icr++) {
                  if (artRemove.lstAnimaux[iar].ipg === vm.ipgListControl[icr]) {
                    vm.ipgListControl.splice(icr, 1);
                  }
                }
              }
              vm.articles.splice(index, 1);
              $mdDialog.hide();
            };
          }
        };

        vm.showPopupEnlevementExceptionel = function (callBack) {

          $mdDialog.show({
            title: translateMsg('ERR007', null),
            templateUrl: 'app/_common/template/confirm.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            multiple: true,
            controller: DialogController
          });

          function DialogController($scope, $mdDialog) {
            var that = $scope;
            that.messages = [translateMsg('MSG032', null)];
            that.cancel = function () {
              $mdDialog.cancel();
            };
            that.confirm = function () {
              callBack();
              $mdDialog.hide();
            };
          }
        };

        /**
         * aller vers la page suivante
         */
        vm.goToContactService = function (web) {
          var articleToCheck = ArticleUtils.initCheckArticles(vm.address, vm.articles);
          ArticleService.checkArticle(articleToCheck).then(function (response) {

            //

            $log.log('Article OK : ' + response);
            if (angular.isUndefined(SessionService.getSessionValue('clientInfoUpdate')) ||
              SessionService.getSessionValue('clientInfoUpdate') === false) {
              if (vm.address.numDetenteur !== vm.oldAddress.numDetenteur ||
                vm.address.idmPorcin !== vm.oldAddress.idmPorcin) {
                SessionService.setSessionValue('clientInfoUpdate', true);
              } else {
                SessionService.setSessionValue('clientInfoUpdate', false);
              }
            }
            SessionService.setSessionValue('currentOrder', articleToCheck);
            SessionService.setSessionValue('currentAddress', vm.address);
            if (web) {
              if (response.data.length > 0 && response.data[0].cause === 'MSG032') {
                vm.showPopupEnlevementExceptionel(
                  function () {
                    $state.go('contactService');
                  });
              } else {
                $state.go('contactService');
              }
            } else if (response.data.length > 0 && response.data[0].cause === 'MSG032') {
              vm.showPopupEnlevementExceptionel(
                function () {
                  changeFooter(true, true, true);
                  $state.go('contactServiceMobile');
                });
            } else {
              changeFooter(true, true, true);
              $state.go('contactServiceMobile');
            }
          })
            .catch(function (error) {
              var errorMsg = [];
              for (var i = 0; i < error.data.length; i++) {
                // On affiche que les erreurs bloquante
                if (error.data[i].bloquant) {
                  errorMsg.push(error.data[i].message);
                }
              }
              displayWarningAlert(errorMsg);
            });
        };

        /**
         * retour à l'écran adresse (depuis le web)
         */
        vm.backToAddress = function () {
          // ENL2-RG03
          vm.currentOrder.lstArticle = vm.articles;
          SessionService.setSessionValue('currentOrder', vm.currentOrder);
          $state.go('adresse');
        };

        /**
         * appui sur Next
         */
        $scope.$on('goToNext', function (event, data) {
          $log.log('event next received !! ' + data);
          vm.setDetails();
        });

        /**
         * appui sur Back
         */
        $scope.$on('goToBack', function (event, data) {
          // ENL2-RG03
          vm.currentOrder.lstArticle = vm.articles;
          SessionService.setSessionValue('currentOrder', vm.currentOrder);
          $log.log('event back received !! ' + data);
          if (vm.articleState === 'list' || (vm.articleState === 'addType' && vm.articles.length === 0)) {
            changeFooter(true, true, false);
            $state.go('adresseMobile');
          } else if (vm.articleState === 'addType' && (vm.typeSel !== '' || vm.articles.length > 0)) {
            vm.articleState = 'list';
          } else {
            vm.articleState = 'addType';
          }
        });

        /**
         * controleur de la modale d'ajout / modification d'articles
         *
         * @param $scope
         * @param $mdDialog
         * @param $filter
         * @param $log
         * @param $timeout
         * @param typeSel
         * @param categories
         * @param edit
         * @param article
         * @param index
         * @param ArticleUtils
         * @param ArticleService
         * @constructor
         */
        function ArticleDialogController($scope, $mdDialog, $filter, $log, $timeout, typeSel, categories, edit, article, index, isModif, ArticleUtils, ArticleService) {
          var that = $scope;
          that.typeSel = typeSel;
          that.categories = [];
          angular.copy(categories, that.categories);
          that.articleValidated = edit;
          that.articlesValid = [];
          that.errors = [];
          that.tableHeaders = [];
          that.translate = $filter('translate');
          that.address = vm.address;
          that.articleValidWithoutField = false;
          that.nbArticles = 0;
          that.isModif = isModif;
          /**
           * paramétrer les entêtes du tableau de saisie des détails des articles
           *
           * @param category
           */
          function setTableHeaders(category) {
            var additionnalFields = ArticleUtils.getAdditionnalFields(category);
            if (angular.isDefined(additionnalFields.ipgCodeLabel) &&
              that.tableHeaders.indexOf(additionnalFields.ipgCodeLabel) < 0) {
              that.tableHeaders.push(additionnalFields.ipgCodeLabel);
              // supprimer l'élément vide si on ajoute l'en-tête de la colonne code pays
              if (that.tableHeaders[1] === '') {
                that.tableHeaders.splice(1, 1);
              }
            } else if (that.tableHeaders[1] !== '' && that.tableHeaders.indexOf(additionnalFields.ipgCodeLabel) < 0) {
              that.tableHeaders.push('');
            }
            if (angular.isDefined(additionnalFields.ipgLabel) &&
              that.tableHeaders.indexOf(additionnalFields.ipgLabel) < 0) {
              that.tableHeaders.push(additionnalFields.ipgLabel);
            }
            if (angular.isDefined(additionnalFields.sexeLabel) &&
              that.tableHeaders.indexOf(additionnalFields.sexeLabel) < 0) {
              that.tableHeaders.push(additionnalFields.sexeLabel);
            }
            if (angular.isDefined(additionnalFields.typeLvLabel) &&
              that.tableHeaders.indexOf(additionnalFields.typeLvLabel) < 0) {
              that.tableHeaders.push(additionnalFields.typeLvLabel);
            }
          }

          /**
           * Ajouter les articles à valider
           *
           * @param category
           * @param index
           * @param addFields
           */
          function addArticlesValid(category, index, addFields) {
            addArticlesValidWithQute(category, index, addFields, 1);
          }

          /**
           * Ajouter les articles à valider avec une quantiter
           *
           * @param category
           * @param index
           * @param addFields
           * @param quantiteLot
           */
          function addArticlesValidWithQute(category, index, addFields, quantiteLot) {

            that.articlesValid.push({
              codeArticle: category.codeArticle,
              codeType: category.codeType,
              saisieIpg: category.saisieIpg,
              saisieSexe: category.saisieSexe,
              saisieTypElv: category.saisieTypElv,
              libelle: category.libelle,
              quantite: category.quantite,
              unite: category.unite,
              uniciteBoucle: category.uniciteBoucle,
              additionnalFields: addFields,
              linesToDisplay: (angular.isDefined(addFields.ipgLabel)) && category.unite !== 'L' ? category.quantite : 1
            });
            if (!angular.equals(addFields, {})) {
              if (category.unite === 'L') {
                that.articlesValid[that.articlesValid.length - 1].animal = {
                  ipg: '',
                  sexe: '',
                  typeLV: '',
                  quantiteLot: quantiteLot,
                  index: index
                };
              } else {
                that.articlesValid[that.articlesValid.length - 1].animal = {
                  codePays: 'FR',
                  ipg: '',
                  sexe: '',
                  typeLV: '',
                  quantiteLot: quantiteLot,
                  index: index
                };
              }
            }
          }

          /**
           * Ajouter un article a éditer
           *
           * @param category
           * @param index
           * @param addFields
           */
          function addExistingArticle(category, index, addFields) {
            that.articlesValid.push({
              codeArticle: category.codeArticle,
              codeType: category.codeType,
              saisieIpg: category.saisieIpg,
              saisieSexe: category.saisieSexe,
              saisieTypElv: category.saisieTypElv,
              libelle: category.libelle,
              quantite: category.quantite,
              uniciteBoucle: category.uniciteBoucle,
              unite: category.unite,
              additionnalFields: addFields,
              linesToDisplay: (angular.isDefined(addFields.ipgLabel)) ? category.quantite : 1
            });
            if (!angular.equals(addFields, {})) {
              that.articlesValid[that.articlesValid.length - 1].animal = {
                codePays: category.lstAnimaux[index].codePays,
                ipg: category.lstAnimaux[index].ipg,
                sexe: category.lstAnimaux[index].sexe,
                typeLV: category.lstAnimaux[index].typeLV,
                quantiteLot: (angular.isDefined(category.lstAnimaux[index].quantiteLot) ? category.lstAnimaux[index].quantiteLot : 1),
                index: index
              };
            }
          }

          // si on a un article en paramètre (modification)
          if (article !== null) {
            for (var ic = 0; ic < that.categories.length; ic++) {
              if (that.categories[ic].codeArticle === article.codeArticle) {
                that.categories[ic].quantite = article.quantite;
                that.categories[ic].lstAnimaux = [];
                for (var ia = 0; ia < article.lstAnimaux.length; ia++) {
                  that.categories[ic].lstAnimaux.push(article.lstAnimaux[ia]);
                }
              }
            }
            for (var ica = 0; ica < that.categories.length; ica++) {
              if (angular.isDefined(that.categories[ica].quantite)) {
                if (that.categories[ica].quantite === '' || that.categories[ica].codeArticle !== article.codeArticle) {
                  that.categories.splice(ica, 1);
                  ica--;
                }
              } else {
                that.categories.splice(ica, 1);
                ica--;
              }
            }
          }

          that.hide = function () {
            $mdDialog.hide();
          };

          that.cancel = function () {
            for (var i = 0; i < that.categories.length; i++) {
              that.categories[i].quantite = '';
              that.categories[i].lstAnimaux = [];
            }
            $mdDialog.cancel();
          };

          function addArticle(iv, addFields) {
            if (that.categories[iv].quantite > 0) {
              that.nbArticles++;
              if (angular.isDefined(that.categories[iv].lstAnimaux)) {
                if (angular.isDefined(addFields.ipgLabel)) {
                  // On ajoute les articles déjà existant
                  var quantiteTraiter = 0;
                  for (var indiceAnimaux = 0; indiceAnimaux < that.categories[iv].lstAnimaux.length && indiceAnimaux < that.categories[iv].quantite; indiceAnimaux++) {
                    addExistingArticle(that.categories[iv], indiceAnimaux, addFields);
                    var qte = that.categories[iv].lstAnimaux[indiceAnimaux].quantiteLot;
                    quantiteTraiter += (angular.isDefined(qte) ? qte : 1);
                  }
                  // Si la quantité est supérieur au saisie
                  for (var ja = quantiteTraiter; ja < that.categories[iv].quantite; ja++) {
                    /* eslint max-depth: off */
                    addArticlesValid(that.categories[iv], ja, addFields);
                  }
                } else {
                  addExistingArticle(that.categories[iv], 0, addFields);
                }
              } else if (angular.isDefined(addFields.ipgLabel)) {

                // Si on est en saisie lot on ne demande qu'un seul indicatif de marquage quel que soit le nombre d'animaux
                if (that.categories[iv].unite === 'L') {
                  addArticlesValidWithQute(that.categories[iv], 0, addFields, that.categories[iv].quantite);
                } else {
                  for (var j = 0; j < that.categories[iv].quantite; j++) {
                    addArticlesValid(that.categories[iv], j, addFields);
                  }
                }
              } else {
                addArticlesValid(that.categories[iv], 0, addFields);
              }
            }
          }

          /**
           * Validate an article
           *
           * @param oldArt
           * @param k
           * @param lstAnimaux
           * @param articles
           * @param translate
           * @param ipgListControlTmp
           * @return {{oldArt: *, lstAnimaux: *, ipgNotFound: boolean, ilc: number, ilct: number}}
           */
          function validateArticle(oldArt, k, lstAnimaux, articles, translate, ipgListControlTmp) {
            if (oldArt.codeArticle !== that.articlesValid[k].codeArticle) {
              oldArt.lstAnimaux = lstAnimaux;
              articles.push(oldArt);
              oldArt = that.articlesValid[k];
              lstAnimaux = [];
            }
            if (that.articlesValid[k].uniciteBoucle) {
              var ipgNotFound = true;
              for (var ilc = 0; ilc < vm.ipgListControl.length; ilc++) {
                if (vm.ipgListControl[ilc] === that.articlesValid[k].animal.ipg &&
                  that.articlesValid[k].animal.ipg !== '') {
                  if (article === null || angular.isUndefined(article.lstAnimaux[k])) {
                    that.errors.push(translate('MSG013') + '(' + vm.ipgListControl[ilc] + ')');
                    ipgNotFound = false;
                  } else if (article.lstAnimaux[k].ipg !== that.articlesValid[k].animal.ipg) {
                    that.errors.push(translate('MSG013') + '(' + vm.ipgListControl[ilc] + ')');
                    ipgNotFound = false;
                  }
                }
              }
              for (var ilct = 0; ilct < ipgListControlTmp.length; ilct++) {
                if (ipgListControlTmp[ilct] === that.articlesValid[k].animal.ipg &&
                  that.articlesValid[k].animal.ipg !== '') {
                  if (article === null || angular.isUndefined(article.lstAnimaux[k])) {
                    that.errors.push(translate('MSG013') + '(' + ipgListControlTmp[ilct] + ')');
                    ipgNotFound = false;
                  } else if (article.lstAnimaux[k].ipg !== that.articlesValid[k].animal.ipg) {
                    that.errors.push(translate('MSG013') + '(' + ipgListControlTmp[ilct] + ')');
                    ipgNotFound = false;
                  }
                }
              }
              if (ipgNotFound) {
                ipgListControlTmp.push(that.articlesValid[k].animal.ipg);
              }
            }
            if (!angular.equals(that.articlesValid[k].additionnalFields, {})) {
              lstAnimaux.push({
                codePays: that.articlesValid[k].animal.codePays,
                ipg: that.articlesValid[k].animal.ipg,
                sexe: that.articlesValid[k].animal.sexe,
                typeLV: that.articlesValid[k].animal.typeLV,
                quantiteLot: that.articlesValid[k].animal.quantiteLot
              });
            }
            return {oldArt: oldArt, lstAnimaux: lstAnimaux};
          }

          /**
           * Affichage & gestion de la page de détails des articles dans la modale
           *
           * @param translate
           */
          function saveValidatedArticles(translate) {
            that.articleValidWithoutField = false;
            vm.address = that.address;
            // formatage des articles pour vérification au back / retour dans l'écran de liste des articles
            var articles = [];
            var oldArt = that.articlesValid[0];
            var lstAnimaux = [];
            var ipgListControlTmp = [];
            for (var k = 0; k < that.articlesValid.length; k++) {
              var __ret = validateArticle(oldArt, k, lstAnimaux, articles, translate, ipgListControlTmp);
              oldArt = __ret.oldArt;
              lstAnimaux = __ret.lstAnimaux;
            }
            if (that.errors.length === 0) {
              oldArt.lstAnimaux = lstAnimaux;
              articles.push(oldArt);
              var articleToCheck = ArticleUtils.initCheckArticles(vm.address, articles);
              ArticleService.checkArticle(articleToCheck).then(function (response) {
                for (var ilcta = 0; ilcta < ipgListControlTmp.length; ilcta++) {
                  vm.ipgListControl.push(angular.copy(ipgListControlTmp[ilcta]));
                }
                $log.log('Articles OK : ' + response);
                // si l'article est OK, on modifie la liste dans l'écran principal (ajout ou remplacement)
                if (article === null) {
                  for (var ia = 0; ia < articles.length; ia++) {
                    vm.articles.push(articles[ia]);
                  }
                } else {
                  vm.articles.splice(index, 1, articles[0]);
                }
                for (var i = 0; i < that.categories.length; i++) {
                  that.categories[i].quantite = '';
                  that.categories[i].lstAnimaux = [];
                }
                $mdDialog.hide();
              })
                .catch(function (error) {
                  var errorFound = false;
                  for (var i = 0; i < error.data.length; i++) {
                    // On n'affiche que les erreurs bloquante
                    if (error.data[i].bloquant) {
                      errorFound = false;
                      for (var j = 0; j < that.errors.length; j++) {
                        if (that.errors[j] === error.data[i].message) {

                          errorFound = true;
                        }
                      }
                      if (!errorFound) {
                        that.errors.push(error.data[i].message);
                        $timeout(function () {
                          // eslint-disable-next-line angular/document-service
                          document.getElementById('articleModalError').scrollIntoView();
                        }, 150);
                      }
                    }
                  }
                });
            }
          }

          function displayDetailPage(translate) {
            that.errors = [];
            that.articlesValid = [];
            that.nbArticles = 0;
            var quantitySet = false;
            // définir les entêtes du tableau
            for (var i = 0; i < that.categories.length; i++) {
              if (String(that.categories[i].quantite) !== 'undefined') {
                if (that.categories[i].quantite > 0) {
                  setTableHeaders(that.categories[i]);
                  quantitySet = true;
                }
              }
            }
            if (!quantitySet) {
              that.errors.push(translate('ADV124'));
            }
            if (that.typeSel.code === '3' && that.address.idmPorcin === '') {
              that.errors.push(translate('ADV054'));
            }
            if (that.errors.length === 0) {
              // instancier les données du tableau
              var hasAddFields = false;
              var quantityWithFields = 0;
              for (var iv = 0; iv < that.categories.length; iv++) {
                if (String(that.categories[iv].quantite) !== 'undefined') {
                  var addFields = ArticleUtils.getAdditionnalFields(that.categories[iv]);
                  if (!angular.equals(addFields, {})) {
                    hasAddFields = true;
                    quantityWithFields += that.categories[iv].quantite;
                  }
                  addArticle(iv, addFields);
                }
              }
              if (quantityWithFields > 27) {
                // ENL2-RG12
                that.errors.push(translate('MSG012'));
              } else if (that.categories.length > 1 && vm.articles.length + that.nbArticles > 6) {
                // ENL2-RG21
                that.errors.push(translate('MSG011'));
              } else if (hasAddFields) {
                that.articleValidated = true;
              } else {
                that.articleValidWithoutField = true;
                that.saveArticlesWeb();
              }
            }
          }

          /* eslint complexity: off */
          that.saveArticlesWeb = function () {
            that.errors = [];
            var translate = $filter('translate');
            // si on valide les détails des articles
            if (that.articleValidated || that.articleValidWithoutField) {
              saveValidatedArticles(translate);
            } else {
              displayDetailPage(translate);
            }
          };

        }

      }
    );
})();
