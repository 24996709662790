(function () {
    /**
     * Service de gestion de l'application
     * @constructor
     */

    function AdvApplicationService() {

        var that = this;

        // vrai ssi l'application est compl�tement charg�e
        that.ready = false;

        that.isReady = function () {
            return that.ready;
        };

        that.setReady = function () {
            that.ready = true;
        };

        that.isAuthentNeeded = function () {
            return true;
        };

    }

    angular
        .module('app').service('AdvApplicationService', AdvApplicationService);
})();
