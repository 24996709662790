(function () {
    /**
     * Service métier de gestion de l'état de l'application
     * @constructor
     */

    angular
        .module('app').factory('ApplicationService', ApplicationService);

    function ApplicationService(AdvApplicationService) {

        // ce service hérite du service AdvApplicationService
        var that = angular.extend(AdvApplicationService, {});

        // ici poser les méthodes sur that pour enrichir le service
        return that;
    }
})();

