(function () {
    /**
     * log général à l'application.
     * @constructor
     */

    function AdvLogDecorator() {

        var self = this;

        self.logToBeDecorated = null;
        self.oldLogFn = null;
        self.oldDebugFn = null;

        self.decorateLogger = function (logToBeDecorated) {

            self.logToBeDecorated = logToBeDecorated;
            self.oldLogFn = this.logToBeDecorated.log;
            self.oldDebugFn = this.logToBeDecorated.debug;

            self.logToBeDecorated.log = function () {
                self.oldLogFn.apply(self.logToBeDecorated, arguments);
            };

            // ajout pour qu'angular mock soit content, voir https://groups.google.com/forum/#!topic/angular/DWOMe6c7L_Q
            self.logToBeDecorated.log.logs = [];

        };

    }

    angular
        .module('app').service('AdvLogDecorator', AdvLogDecorator);
})();
