(function () {
    angular
        .module('app')
        .service('ArticleService', ArticleService);
    /**
     * Gère le métier spécifique aux articles
     * @constructor
     */
    function ArticleService(EspeceService, CommandeService) {

      this.getEspeces = function () {
          return EspeceService.getEspeces();
      };

      this.getTypesEspece = function () {
        return EspeceService.getTypesEspece();
      };

      this.checkArticle = function (article) {
        return CommandeService.controleBoucle(article);
      };
    }

})();
