(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    /* global LZString */
    .controller('DemandeController', function ($scope, $rootScope, $log, $state, $translate, $filter, $mdDialog, $document, $sce, SessionService, CommandeService, ClientService) {

      var vm = this;

      vm.init = function () {
        // variables
        var LS_KEY_TOKEN = 'token';
        vm.personne = SessionService.getValue(LS_KEY_TOKEN).personne;
        vm.orderState = 'checkOrder';
        vm.currentOrder = SessionService.getSessionValue('currentOrder');
        vm.currentAddress = SessionService.getSessionValue('currentAddress');
        vm.currentOrder.precision = vm.currentAddress.complementInfos;
        vm.client = SessionService.getSessionValue('client');
        vm.conf = SessionService.getSessionValue('conf');
        vm.checkConditions = false;
        vm.checkEnlevement = false;
        vm.nbOrder = '';
        vm.telCenter = '';
        vm.center = '';
        vm.articlesWithPrice = [];
        vm.totalPrice = 0;
        vm.bankRequest = '';
        vm.invoice = {};
        vm.tpeFormTarget = 'iframeService';

        if (SessionService.getSessionValue('web').desktop) {
          vm.currentOrder.typeAcces = 'N';
        } else {
          vm.currentOrder.typeAcces = 'P';
        }

        // fonctions
        vm.saveAll = saveAll;
        vm.saveOrder = saveOrder;
        vm.cancelOrder = cancelOrder;
        vm.validateOrder = validateOrder;
        vm.backToAddress = backToAddress;
        vm.backToArticle = backToArticle;
        vm.backToContact = backToContact;
        vm.goToPay = goToPay;

        // appel fonctions
        setFormTarget();
        initInvoice();
      };

      /**
       * configurer l'attribut target du formulaire de paiement suivant
       * que l'on soit dans agranet ou non
       */
      function setFormTarget() {
        /* eslint eqeqeq: "off" */
        if (self == top) {
          vm.tpeFormTarget = '_top';
        }
      }

      /**
       * récupération des infomations de prix des articles
       */
      function initInvoice() {
        CommandeService.controlePrix(vm.currentOrder).then(function (response) {
            if (response.data.lstLignes.length > 0) {
              for (var i = 0; i < response.data.lstLignes.length; i++) {
                vm.articlesWithPrice.push(response.data.lstLignes[i]);
              }
              if (response.data.prixTotalTtc > 0) {
                vm.articlesWithPrice.push({designation: 'ADV096', totalHt: response.data.prixTotalHt});
                vm.articlesWithPrice.push({designation: 'ADV097', totalHt: response.data.prixTotalTva});
                vm.articlesWithPrice.push({designation: 'ADV098', totalHt: response.data.prixTotalTtc});
                vm.totalPrice = response.data.prixTotalTtc;
              } else {
                vm.checkConditions = true;
              }
              vm.invoice = response.data;
              vm.currentOrder.facture = {};
              angular.copy(vm.invoice, vm.currentOrder.facture);
            } else {
              // s'il n'y a pas d'article payant, on valide les conditions defacto
              vm.checkConditions = true;
            }
          })
          .catch(function (error) {
            $log.log('Erreur sur prix : ' + error);
          });
      }

      /**
       * enregistrer la demande + client et adresse si nécessaire
       *
       * @param web
       */
      function saveAll(web) {
        if (SessionService.getSessionValue('clientInfoUpdate')) {
          vm.currentOrder.modifClient = true;
          ClientService.updateClient(vm.personne.login, vm.client).then(function (response) {
            $log.log('MAJ client ' + response);
            ClientService.updateAdresse(vm.personne.login, vm.currentAddress).then(function (response) {
              $log.log('MAJ adresse : ' + response);
              vm.saveOrder(web);
            })
              .catch(function (error) {
                $log.log('Erreur lors de la mj de adresse : ' + error);
              });
          })
            .catch(function (error) {
              $log.log('Erreur lors de la mj de client : ' + error);
            });
        } else {
          vm.currentOrder.modifClient = false;
          $log.log('Enregistrement commande ');
          saveOrder(web);
        }
      }

      /**
       * enregistrer la commande
       *
       * @param web - VRAI si depuis web
       */
      function saveOrder(web) {
        SessionService.setValue('currentOrder', {
          order: vm.currentOrder,
          address: vm.currentAddress,
          client: vm.client
        });
        if (web) {
          $state.go('home');
        } else {
          ClientService.getCallCenter(vm.currentAddress.numSaria).then(function (response) {
            var tel = response.data.numTel;
            for (var it = 0; it < tel.length; it++) {
              vm.telCenter += tel.substr(it, 2) + ' ';
              it++;
            }
            vm.telCenter = vm.telCenter.substr(0, vm.telCenter.length - 1);
            vm.center = response.data.nom;
          })
            .catch(function (error) {
              $log.log('Erreur lors de la recuperation du centre saria ' + error);
            });
          // vm.orderState = 'orderValid';
          $rootScope.$broadcast('changeFooter', {
            displayBack: false,
            displayMenu: true,
            displayNext: true,
            displayExit: false
          });
          $state.go('homeMobile');
        }
      }

      /**
       * annuler la demande
       *
       * @param web - TRUE si depuis le web (pas mobile)
       */
      function cancelOrder(web) {
        var translate = $filter('translate');
        $mdDialog.show({
          title: translate('ERR007'),
          templateUrl: 'app/_common/template/confirm.dialog.html',
          parent: angular.element($document.body),
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: true,
          locals: {
            web: web
          },
          multiple: true,
          controller: DialogController
        });
      }

      /**
       * validation demande & conditions
       */
      function validateOrder() {
        vm.saveAll(false);
      }

      /**
       * retour à adresse
       */
      function backToAddress() {
        if (vm.invoice.prixTotalTtc > 0) {
          CommandeService.supprimerFacture(vm.invoice.idFacture);
        }
        $state.go('adresse');
      }

      /**
       * retour à article
       */
      function backToArticle() {
        if (vm.invoice.prixTotalTtc > 0) {
          CommandeService.supprimerFacture(vm.invoice.idFacture);
        }
        $state.go('article');
      }

      /**
       * retour à contactService
       */
      function backToContact() {
        if (vm.invoice.prixTotalTtc > 0) {
          CommandeService.supprimerFacture(vm.invoice.idFacture);
        }
        $state.go('contactService');
      }

      /**
       * aller au paiement, chargement de l'iframe avec la portail de la banque
       */
      function goToPay() {
        vm.payment = {};
        vm.idTemp = '';
        var orderToSend = {
          commande: vm.currentOrder,
          client: null,
          adresse: null
        };
        if (SessionService.getSessionValue('clientInfoUpdate')) {
          orderToSend.adresse = vm.currentAddress;
          orderToSend.client = vm.client;
        }
        CommandeService.enregistrerCommandeAttente(orderToSend).then(function (response) {
          var urlData = {
            token: SessionService.getValue('token'),
            orderId: response.data
          };
          vm.idTemp = response.data.id;

          var orderSerialised = angular.toJson(urlData);
          vm.invoice.orderSerialised = LZString.compressToEncodedURIComponent(orderSerialised);
          vm.invoice.idTemp = vm.idTemp;
          vm.invoice.email = vm.client.email;

          CommandeService.paiementCommande(vm.invoice).then(function (response) {
              $log.log('Ok pour paiement');
              vm.payment = response.data;
              vm.payment.email = vm.client.email;
              vm.payment.urlForm = $sce.trustAsResourceUrl(vm.payment.urlForm);
              vm.orderState = 'payment';
            })
            .catch(function (error) {
              $log.log('Erreur lors de l appel pour le paiement : ' + error);
            });
        })
        .catch(function (error) {
          $log.log('Erreur lors de l appel pour le paiement : ' + error);
        });
      }

      /**
       * appui sur Back
       */
      $scope.$on('goToBack', function (event, data) {
        $log.log('event back received !! ' + data);
        if (vm.invoice.prixTotalTtc > 0) {
          CommandeService.supprimerFacture(vm.invoice.idFacture);
        }
        $rootScope.$broadcast('changeFooter', {
          displayBack: true,
          displayMenu: true,
          displayNext: true,
          displayExit: false
        });
        $state.go('contactServiceMobile');
      });

      /**
       * appui sur Menu/Quitter
       */
      $scope.$on('goToMenu', function (event, data) {
        $log.log('event back received !! ' + data);
        if (vm.orderState === 'orderValid') {
          $log.log('QUITTER !');
        }
      });

      /**
       * Controleur pour la confirmation d'annulation de demande
       *
       * @param $scope
       * @param $mdDialog
       * @param web
       * @constructor
       */
      function DialogController($scope, $mdDialog, web) {
        var translate = $filter('translate');
        var that = $scope;
        that.messages = [translate('MSG005')];
        that.cancel = function () {
          $mdDialog.cancel();
        };
        that.confirm = function () {
          vm.displayCancel = false;
          if (vm.invoice.prixTotalTtc > 0) {
            CommandeService.supprimerFacture(vm.invoice.idFacture);
          }
          SessionService.setSessionValue('currentOrderId', undefined);
          SessionService.setSessionValue('currentOrder', undefined);
          SessionService.setSessionValue('currentAddress', undefined);
          SessionService.setSessionValue('payment', undefined);
          SessionService.setSessionValue('cancelOrder', true);
          if (web) {
            $state.go('home');
          } else {
            $rootScope.$broadcast('changeFooter', {
              displayBack: false,
              displayMenu: true,
              displayNext: true,
              displayExit: false
            });
            $state.go('homeMobile');
          }
          $mdDialog.hide();
        };
      }

      vm.init();

    });
})();
