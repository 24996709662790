(function () {
  /**
   * Composant d'interception des appels http
   *
   * TODO : ajouter un autre intercepteur pour les retours dont le rôle est "Tout code http n'étant pas dans la tranche des 200 renvoie sur l'authent, avec message utilisateur"
   * @constructor
   */

  angular
    .module('app').service('HttpInterceptor', HttpInterceptor);

  function HttpInterceptor($log, SessionService) {

    var that = this;

    that.listeners = [];

    that.addListener = function (listener) {
      that.listeners.push(listener);
    };

    that.request = function (config) {
      var nbListeners = that.listeners.length;
      for (var i = 0; i < nbListeners; i++) {
        that.listeners[i].request(config);
      }

      // ajout du jeton si on est connecté.
      if (angular.isDefined(SessionService.getSessionValue('token')) && SessionService.getSessionValue('token') !== null) {
        config.headers.Authorization = SessionService.getSessionValue('token').jeton;
      } else if (SessionService.getInfoConnexion() !== null) {
        var infoConnexion = SessionService.getInfoConnexion();
        if (infoConnexion) {
          config.headers.Authorization = infoConnexion.jeton;
        }
      }

      return config;
    };
  }
})();
