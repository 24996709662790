(function () {
    angular
        .module('app')
        .service('HomeService', HomeService);
    /**
     * Gère le métier spécifique à l'accueil
     * @constructor
     */
    function HomeService(TechService, CommandeService, ClientService) {

      this.getFlashInfo = function () {
          return TechService.getFlashInfo();
      };

      this.getClientOrders = function (userId, nbMax) {
        return CommandeService.getClientCommandes(userId, null, nbMax);
      };

      this.getClient = function (userId) {
        return ClientService.getClient(userId);
      };

    }

})();
