(function () {
  /* global LZString */
  angular
    .module('app')
    .config(routesConfig)
    .run(routeStart);

  /**
   * @name : coucou
   * @ngInject */
  function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    $locationProvider.html5Mode(true).hashPrefix('!');
    $stateProvider
      .state('auth', {
        url: '/login?id',
        parent: 'root',
        params: {
          refresh: {id: false}
        },
        views: {
          header: {
            templateUrl: 'app/_common/template/headerLogin.html',
            controller: 'HeaderController as header'
          },
          content: {
            templateUrl: 'auth.html',
            controller: 'AuthController as auth'
          }
        }
      })
      .state('root', {
        abstract: true,
        templateUrl: 'app/_common/template/root.html'
      })
      .state('main', {
        parent: 'root',
        abstract: true,
        views: {
          header: {
            templateUrl: 'app/_common/template/header.html',
            controller: 'HeaderController as header'
          },
          footer: {
            templateUrl: 'app/_common/template/footer.html',
            controller: 'FooterController as footer'
          }
        }
      })
      .state('mainMobile', {
        parent: 'root',
        abstract: true,
        views: {
          header: {
            templateUrl: 'app/_common/template/headermobile.html',
            controller: 'HeaderController as header'
          },
          footer: {
            templateUrl: 'app/_common/template/footermobile.html',
            controller: 'FooterController as footer'
          }
        }
      })
      .state('home', {
        url: '^/accueil',
        parent: 'main',
        views: {
          'content@root': {
            templateUrl: 'app/home/home.html',
            controller: 'HomeController as home'
          }
        }
      })
      .state('homeMobile', {
        url: '^/accueilMobile',
        parent: 'mainMobile',
        views: {
          'content@root': {
            templateUrl: 'app/home/homemobile.html',
            controller: 'HomeController as home'
          }
        }
      })
      .state('flashInfoMobile', {
        url: '^/flashInfoMobile',
        parent: 'mainMobile',
        params: {
          infos: null
        },
        views: {
          'content@root': {
            templateUrl: 'app/flashInfo/flashInfo.html',
            controller: 'FlashInfoController as flashInfo'
          }
        }
      })
      .state('adresse', {
        url: '^/adresse',
        parent: 'main',
        views: {
          'content@root': {
            templateUrl: 'app/adresse/adresse.html',
            controller: 'AdresseController as adresse'
          }
        }
      })
      .state('adresseMobile', {
        url: '^/adresseMobile',
        parent: 'mainMobile',
        views: {
          'content@root': {
            templateUrl: 'app/adresse/adressemobile.html',
            controller: 'AdresseController as adresse'
          }
        }
      })
      .state('article', {
        url: '^/article',
        parent: 'main',
        views: {
          'content@root': {
            templateUrl: 'app/article/article.html',
            controller: 'ArticleController as article'
          }
        }
      })
      .state('articleMobile', {
        url: '^/articleMobile',
        parent: 'mainMobile',
        params: {
          address: null
        },
        views: {
          'content@root': {
            templateUrl: 'app/article/articlemobile.html',
            controller: 'ArticleController as article'
          }
        }
      })
      .state('contactService', {
        url: '^/contactetservice',
        parent: 'main',
        views: {
          'content@root': {
            templateUrl: 'app/contactService/contactService.html',
            controller: 'ContactServiceController as contactService'
          }
        }
      })
      .state('contactServiceMobile', {
        url: '^/contactetserviceMobile',
        parent: 'mainMobile',
        views: {
          'content@root': {
            templateUrl: 'app/contactService/contactServicemobile.html',
            controller: 'ContactServiceController as contactService'
          }
        }
      })
      .state('commandeMobile', {
        url: '^/commandeMobile',
        parent: 'mainMobile',
        views: {
          'content@root': {
            templateUrl: 'app/commande/commande.html',
            controller: 'CommandeController as commande'
          }
        }
      })
      .state('demande', {
        url: '^/demande',
        parent: 'main',
        views: {
          'content@root': {
            templateUrl: 'app/demande/demande.html',
            controller: 'DemandeController as demande'
          }
        }
      })
      .state('demandeMobile', {
        url: '^/demandeMobile',
        parent: 'mainMobile',
        views: {
          'content@root': {
            templateUrl: 'app/demande/demandemobile.html',
            controller: 'DemandeController as demande'
          }
        }
      });
    $urlRouterProvider.otherwise('/');
  }

  /** @ngInject */
  function routeStart($state, $location, $log, SessionService) {
    if (angular.isDefined($location.search().token)) {
      var tokenObj = {
        jeton: $location.search().token,
        personne: {
          login: $location.search().nuclie
        }};
      SessionService.setValue('token', tokenObj);
      $log.log('Token found in session');
    }

    if (angular.isDefined($location.search().payment)) {
      SessionService.setValue('payment', {
        status: $location.search().payment
      });
      $log.log('Payment found in session');
    } else {
      SessionService.setValue('payment', undefined);
      $log.log('Payment not found in session');
    }

    if (angular.isDefined($location.search().order) && $location.search().order !== 'null') {
      var orderDecoded = LZString.decompressFromEncodedURIComponent($location.search().order);
      var order = angular.fromJson(orderDecoded);
      SessionService.setValue('currentOrderId', order.orderId);
      SessionService.setValue('token', order.token);
      $log.log('Order found in session');
    } else {
      SessionService.setValue('currentOrderId', undefined);
      $log.log('Order not found in session');
    }

    $state.go('auth');
  }
})();
