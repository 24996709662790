(function () {
  /**
   * Service métier de gestion des commandes
   * @constructor
   */

  angular
    .module('app').factory('CommandeService', CommandeService);

  /* eslint max-params: "off" */
  function CommandeService($q, SessionService, $log, ConfigService, $http) {

    var that = this;

    /**
     * requête pour récupérer les commandes d'un client
     * @param userId
     * @returns {*}
     */
    that.getClientCommandes = function (userId, codejde, nbMax) {
      var url = ConfigService.getBackendUrl() + 'commande/client/' + userId;
      $log.log('Demande de recuperation des commandes du client ');
      return $http.get(url, {params: {codjde: codejde, nbMax: nbMax}});
    };

    /**
     * requête pour le controle des entrées de la commande
     * @param order
     * @returns {*}
     */
    that.controleBoucle = function (order) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/controle/boucle';
      $log.log('Demande du controle des entrees de la commande ');

      $http.post(url, order).then(function (result) {
        if (result.status === 200) {
          resultQ.resolve(result);
        } else {
          resultQ.reject(result);
        }
      }, function (error) {
        $log.log('Erreur lors du controle des entrees de la commande ');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour le calcul du montant de la facture
     * @param order
     * @returns {*}
     */
    that.controlePrix = function (order) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/controle/prix';
      $log.log('Demande de calcul du montant de la facture ');

      $http.post(url, order).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors du calcul du montant de la facture');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour enregistrer une commande
     * @param order
     * @returns {*}
     */
    that.enregistrerCommande = function (order) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/';
      $log.log('Demande de sauvegarde de la commande ');

      $http.put(url, order).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors de la sauvegarde de la commande');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour supprimer une facture
     * @param invoice
     * @returns {*}
     */
    that.supprimerFacture = function (invoiceId) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/facture';
      $log.log('Demande de suppression de facture ');

      $http.delete(url, {params: {idFacture: invoiceId}}).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors de la suppression d\'une facture');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour initier un paiement en ligne
     * @param invoice
     * @returns {*}
     */
    that.paiementCommande = function (invoice) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/paiement';
      $log.log('Demande de lancement du paiement ');

      $http.post(url, invoice).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors du lancement du paiement');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour enregistrer une commande en attente de paiement
     * @param order
     * @returns {*}
     */
    that.enregistrerCommandeAttente = function (waitingOrder) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/attentepaiement';
      $log.log('Demande de sauvegarde de la commande en attente');

      $http.put(url, waitingOrder).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors de la sauvegarde de la commande en attente');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour valider une commande en attente de paiement
     * @param order
     * @returns {*}
     */
    that.validerCommandeAttente = function (waitingOrderId, paymentState) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'commande/attentepaiement/' + paymentState;
      $log.log('Demande de validation de la commande en attente');

      $http.post(url, waitingOrderId).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors de la validation de la commande en attente');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    return that;
  }
})();
