(function () {
    /**
     * Service métier d'authentification
     * @constructor
     */

    angular
        .module('app').factory('ErrorService', ErrorService);

    function ErrorService($log, AdvErrorService) {

        // ce service hérite du service AdvErrorService
        var that = angular.extend(AdvErrorService, {});
        // ici poser les méthodes sur that pour enrichir le service
        return that;
    }

})();
