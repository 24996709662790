(function () {
  /**
   * Service métier de gestion du client
   * @constructor
   */

  angular
    .module('app').factory('ClientService', ClientService);

  /* eslint max-params: "off" */
  function ClientService($q, SessionService, $log, ConfigService, $http) {

    var that = this;

    /**
     * requête pour récupérer les informations d'un client
     * @param userId
     * @returns {*}
     */
    that.getClient = function (userId) {
      var url = ConfigService.getBackendUrl() + 'client/' + userId;
      $log.log('Demande de recuperation du client ');
      return $http.get(url);
    };

    /**
     * requête pour récupérer les adresses d'un client
     * @param userId
     * @returns {*}
     */
    that.getAddresses = function (userId) {
      var url = ConfigService.getBackendUrl() + 'client/' + userId + '/adresses/';
      $log.log('Demande de recuperation des adresses ');
      return $http.get(url);
    };

    /**
     * requête pour mettre à jour un client
     *
     * @param userId - ID client
     * @param user - client
     */
    that.updateClient = function (userId, user) {
      var url = ConfigService.getBackendUrl() + 'client/' + userId;
      return $http.post(url, user);
    };

    /**
     * requête de mise à jour d'une adresse d'un client
     *
     * @param userId - ID client
     * @param adresse - adresse
     */
    that.updateAdresse = function (userId, adresse) {
      var url = ConfigService.getBackendUrl() + 'client/' + userId + '/adresses/';
      return $http.post(url, adresse);
    };

    /**
     * requête pour récupérer les informations d'un centre téléphonique
     * @param centerId
     * @returns {*}
     */
    that.getCallCenter = function (centerId) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'client/centre/tel';
      $log.log('Demande de recuperation du centre tel ');

      $http.get(url, {params: {code: centerId}})
        .then(function (result) {
          resultQ.resolve(result);
        }, function (error) {
          $log.log('Erreur lors de la recuperation du centre tel');
          resultQ.reject(error);
        });
      return resultQ.promise;
    };

    return that;
  }
})();
