(function () {
  /**
   * Service métier de configuration de l'application
   * @constructor
   */

  angular
    .module('app').factory('ConfigService', ConfigService);

  function ConfigService(AdvConfigService) {

    // ce service hérite du service AdvConfigService
    var that = angular.extend(AdvConfigService, {});
    // ici poser les méthodes sur that pour enrichir le service

    that.getApplication = function () {
      return that.params.typeApplication;
    };

    that.getPageAccueil = function () {
      return that.params.routeAccueil;
    };

    return that;
  }
})();
