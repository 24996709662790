(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    .provider('ArticleUtils', function () {

      var vm = this;
      var uniqCheck = [];

      // un provider doit implémenter une méthode pour récupérer son instance
      vm.$get = function () {
        return this;
      };

      /**
       * parametrage des champs du formulaire de détail
       *
       * @param category - espèce
       * @return objet avec les labels des champs additionnels
       */
      vm.getAdditionnalFields = function (category) {
        var additionnalFields = {};

        // Type: bovin, Autres, Ovin, Caprin
        if (category.codeType === '1' || category.codeType === '6' ||
          category.codeType === '2' || category.codeType === '7') {
          // type ovin ou caprin
          if (category.unite === 'Q') {
            if (category.saisieIpg === 'Oui' || category.saisieIpg === 'Fac') {
              additionnalFields.ipgCodeLabel = 'ADV093';
              additionnalFields.ipgLabel = 'ADV094';
            }
            if (category.saisieSexe) {
              additionnalFields.sexeLabel = 'ADV048';
            }
            if (category.saisieTypElv) {
              additionnalFields.typeLvLabel = 'ADV049';
            }
          } else if (category.unite === 'L') {
            additionnalFields.ipgLabel = 'ADV095';
          }
        } else if (category.codeType === '4') {
          // type equin
          additionnalFields.ipgLabel = 'ADV047';
        }
        return additionnalFields;
      };

      /**
       * vérifie la validité des détails entrés pour un animal
       *
       * @param category - espèce
       * @param animal - animal courant
       * @return string non vide si erreur
       */
      vm.checkDetails = function (category, animal) {
        var message = '';
        if (category.codeType === '4') {
          // ENL2-RG19
          if (angular.isDefined(animal.ipg)) {
            var res = animal.ipg.match(/^[0-9]{8}[a-zA-Z]$/g);
            if (res === null) {
              message = 'MSG016';
            }
          } else {
            message = 'ERR013';
          }
        } else if (category.codeType === '1') {
          // ENL2-RG08
          if ((category.saisieIpg === 'Oui' && angular.isUndefined(animal.ipg)) ||
            (category.saisieIpg === 'Oui' && animal.ipg.length === 0)) {
            message = 'ERR013';
          } else if (category.saisieIpg === 'Oui' || (category.saisieIpg === 'Fac' && animal.ipg.length > 0)) {
            if (angular.isDefined(animal.ipg)) {
              var realIpg = animal.ipg.length;
              if (realIpg < category.minIPG || realIpg > category.maxIPG) {
                message = 'MSG008';
              }
            } else {
              message = 'ERR013';
            }
          }
        }
        if (category.uniciteBoucle && message === '') {
          if (animal.ipg.length > 0) {
            if (vm.checkUniqueness()) {
              var found = false;
              for (var i = 0; i < uniqCheck.length; i++) {
                if (uniqCheck[i].codeArticle === category.codeArticle) {
                  found = true;
                  uniqCheck[i].ipgList.push(animal.ipg);
                }
              }
              if (!found) {
                uniqCheck.push({codeArticle: category.codeArticle, ipgList: [animal.ipg]});
              }
            } else {
              message = 'MSG013';
            }
          }
        }
        return message;
      };

      /**
       * initialise un objet pour la vérification par le serveur
       *
       * @param address - adresse courante
       * @return objet pour vérification d'articles
       */
      var initCheck = function (address) {
        var chkArticle = {};
        chkArticle.codejde = address.codjde;
        chkArticle.idmPorcin = address.idmPorcin;
        chkArticle.numClient = address.nuclie;
        chkArticle.numSaria = address.numSaria;
        chkArticle.numDetenteur = address.numDetenteur;
        chkArticle.lstArticle = [];
        return chkArticle;
      };

      /**
       * gère IPG + code pays suivant espèce
       *
       * @param article
       * @param animalIndex
       * @return IPG d'un animal
       */
      vm.addCountryCode = function (article, animalIndex) {
        // si code pays
        if (angular.isDefined(article.lstAnimaux[animalIndex].codePays) && angular.isDefined(article.lstAnimaux[animalIndex].ipg)) {
			if ((article.codeType === '1' && (article.saisieIpg === 'Oui' || (article.saisieIpg === 'Fac' && article.lstAnimaux[animalIndex].ipg.length > 0))) ||
			((article.codeType === '2' || article.codeType === '7') && article.unite === 'Q' &&
			(article.saisieIpg === 'Oui' || (article.saisieIpg === 'Fac' && article.lstAnimaux[animalIndex].ipg.length > 0)))) {
				return article.lstAnimaux[animalIndex].codePays + article.lstAnimaux[animalIndex].ipg;
			}
			return article.lstAnimaux[animalIndex].ipg;
		} else if (angular.isDefined(article.lstAnimaux[animalIndex].ipg)) {
			return article.lstAnimaux[animalIndex].ipg;
		}
        return '';
      };

      /**
       * initialise un objet pour la vérification par le serveur d'un animal
       *
       * @param address - adresse courante
       * @param category - espèce courante
       * @param animal - animal courant
       * @return objet pour vérification d'un animal
       */
      vm.initCheckAnimal = function (address, category, animal) {
        var chkArticle = initCheck(address);
        chkArticle.lstArticle.push({
          codeArticle: category.codeArticle,
          quantite: 1,
          lstAnimaux: []
        });
        if (angular.isDefined(animal.codePays)) {
          chkArticle.lstArticle[0].lstAnimaux.push({
            codePays: animal.codePays,
            ipg: animal.ipg,
            sexe: animal.sexe,
            typeLV: animal.typeLV,
            quantiteLot: animal.quantiteLot
          });
        } else {
          chkArticle.lstArticle[0].lstAnimaux.push({
            ipg: animal.ipg,
            sexe: animal.sexe,
            typeLV: animal.typeLV,
            quantiteLot: animal.quantiteLot

          });
        }
        // copie manuelle de l'espece
        var articleTest = {
          code: category.code,
          codeType: category.codeType,
          saisieSexe: category.saisieSexe,
          saisieIpg: category.saisieIpg,
          uniciteBoucle: category.uniciteBoucle,
          uniteMax: category.uniteMax,
          minIPG: category.minIPG,
          maxIPG: category.maxIPG,
          saisieTypElv: category.saisieTypElv,
          unite: category.unite,
          libelle: category.libelle
        };
        articleTest.lstAnimaux = [];
        articleTest.lstAnimaux.push(animal);
        // ajout code pays si nécessaire
        // chkArticle.lstArticle[0].lstAnimaux[0].ipg = vm.addCountryCode(articleTest, 0);
        return chkArticle;
      };

      /**
       * initialise un objet pour la vérification par le serveur d'un article
       *
       * @param address - adresse courante
       * @param category - espèce avec animaux
       * @return objet pour vérification d'une espèce
       */
      vm.initCheckArticle = function (address, category) {
        var chkArticle = initCheck(address);
        chkArticle.lstArticle.push({
          codeArticle: category.codeArticle,
          quantite: category.quantite,
          lstAnimaux: []
        });
        for (var i = 0; i < category.lstAnimaux.length; i++) {
          if (angular.isDefined(category.lstAnimaux[i].codePays)) {
            chkArticle.lstArticle[0].lstAnimaux.push({
              codePays: category.lstAnimaux[i].codePays,
              ipg: category.lstAnimaux[i].ipg,
              sexe: category.lstAnimaux[i].sexe,
              typeLV: category.lstAnimaux[i].typeLV,
              quantiteLot: category.lstAnimaux[i].quantiteLot

            });
          } else {
            chkArticle.lstArticle[0].lstAnimaux.push({
              codePays: '',
              ipg: category.lstAnimaux[i].ipg,
              sexe: category.lstAnimaux[i].sexe,
              typeLV: category.lstAnimaux[i].typeLV,
              quantiteLot: category.lstAnimaux[i].quantiteLot
            });
          }
          // chkArticle.lstArticle[0].lstAnimaux[i].ipg = vm.addCountryCode(category, i);
        }
        return chkArticle;
      };

      /**
       * initialise un objet pour la vérification par le serveur d'un article
       *
       * @param address - adresse courante
       * @param articles - liste des articles de la commande
       * @return objet formater pour vérifier la commande
       */
      vm.initCheckArticles = function (address, articles) {
        var chkArticle = initCheck(address);
        for (var i = 0; i < articles.length; i++) {
          chkArticle.lstArticle.push({
            codeArticle: articles[i].codeArticle,
            quantite: articles[i].quantite,
            libelle: articles[i].libelle,
            lstAnimaux: []
          });
          for (var j = 0; j < articles[i].lstAnimaux.length; j++) {
            if (angular.isDefined(articles[i].lstAnimaux[j].codePays)) {
              chkArticle.lstArticle[i].lstAnimaux.push({
                codePays: articles[i].lstAnimaux[j].codePays,
                ipg: articles[i].lstAnimaux[j].ipg,
                sexe: articles[i].lstAnimaux[j].sexe,
                typeLV: articles[i].lstAnimaux[j].typeLV,
                quantiteLot: articles[i].lstAnimaux[j].quantiteLot
              });
            } else {
              chkArticle.lstArticle[i].lstAnimaux.push({
                codePays: '',
                ipg: articles[i].lstAnimaux[j].ipg,
                sexe: articles[i].lstAnimaux[j].sexe,
                typeLV: articles[i].lstAnimaux[j].typeLV,
                quantiteLot: articles[i].lstAnimaux[j].quantiteLot
              });
            }
            // chkArticle.lstArticle[i].lstAnimaux[j].ipg = vm.addCountryCode(articles[i], j);
          }
        }
        return chkArticle;
      };

      /**
       * Vérifie que l'ipg entré est unique
       *
       * @return {boolean} - True si unique
       */
      vm.checkUniqueness = function (codeArticle, ipg) {
        // ENL2-RG13
        for (var i = 0; i < uniqCheck.length; i++) {
          if (uniqCheck[i].codeArticle === codeArticle) {
            for (var j = 0; j < uniqCheck[i].ipgList.length; j++) {
              if (uniqCheck[i].ipgList[j] === ipg) {
                return false;
              }
            }
          }
        }
        return true;
      };

    });
})();
