(function () {
    /**
     * Service d'authentification
     * @constructor
     */

    function AdvAuthentService() {

        var that = this;

        that.isAuthentNeeded = function () {
            return true;
        };

    }

    angular
        .module('app').service('AdvAuthentService', AdvAuthentService);
})();
