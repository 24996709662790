(function () {
  /**
   * Service métier pour ls infos techniques
   * @constructor
   */

  angular
    .module('app').factory('TechService', TechService);

  /* eslint max-params: "off" */
  function TechService($q, SessionService, $log, ConfigService, $http) {

    var that = this;

    /**
     * requête pour récupérer la configuration
     * @returns {*}
     */
    that.getConf = function () {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'tech/conf';
      $log.log('Demande de recuperation de la conf ');

      $http.get(url).then(function (result) {
        resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors de la recuperation de la conf');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    /**
     * requête pour récupérer le flash info
     * @returns {*}
     */
    that.getFlashInfo = function () {
      var url = ConfigService.getBackendUrl() + 'tech/flashInfo';
      $log.log('Demande de recuperation du flash info ');
      return $http.get(url);
    };

    /**
     * requête pour récupérer un message
     * @param code
     * @returns {*}
     */
    that.getMessage = function (code) {
      var resultQ = $q.defer();
      var url = ConfigService.getBackendUrl() + 'tech/msg';
      $log.log('Demande de recuperation du message ');

      $http.get(url, {params: {code: code}})
        .then(function (result) {
          resultQ.resolve(result);
      }, function (error) {
        $log.log('Erreur lors de la recuperation du message');
        resultQ.reject(error);
      });
      return resultQ.promise;
    };

    return that;
  }
})();
