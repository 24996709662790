(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    /* global moment */
    .controller('AdresseController', function ($rootScope, $scope, $log, $state, $translate, $filter, $mdDialog, $document, ClientService, SessionService, CommandeService, EspeceService) {

      var vm = this;
      var LS_KEY_TOKEN = 'token';

      // Adresse selectionnee
      vm.address = '';
      vm.complementInfos = '';
      // infos personne depuis le localstorage
      vm.personne = SessionService.getValue(LS_KEY_TOKEN).personne;
      vm.addressState = 'select';
      vm.telCenter = '';
      vm.center = '';
      vm.noAddressSelected = true;
      vm.addresses = [];

      function init() {
        ClientService.getAddresses(vm.personne.login)
          .then(function (response) {
            var oldAddress = SessionService.getSessionValue('currentAddress');
            vm.addresses = response.data;

            // Si il n'y a qu'une seul adresse on la séléctionne de base
            if ((angular.isUndefined(vm.address) || vm.address === '') && vm.addresses.length === 1) {
              oldAddress = vm.addresses[0];
              vm.complementInfos = '';
              if (angular.isUndefined(oldAddress.complementInfos)) {
                oldAddress.complementInfos = '';
              }
            }

            for (var ia = 0; ia < vm.addresses.length; ia++) {
              if (angular.isDefined(oldAddress) && oldAddress.codjde === vm.addresses[ia].codjde) {
                vm.addresses[ia].checked = true;
                vm.noAddressSelected = false;
                vm.complementInfos = oldAddress.complementInfos;
              } else {
                vm.addresses[ia].checked = false;
              }
            }

            // ENL1-RG04
            ClientService.getCallCenter(vm.addresses[0].numSaria).then(function (response) {
              var tel = response.data.numTel;
              for (var it = 0; it < tel.length; it++) {
                vm.telCenter += tel.substr(it, 2) + ' ';
                it++;
              }
              vm.telCenter = vm.telCenter.substr(0, vm.telCenter.length - 1);
              vm.center = response.data.nom;
            })
              .catch(function (error) {
                $log.log('Erreur lors de la recuperation du centre saria ' + error);
              });
          })
          .catch(function (error) {
            $log.log('Get client addresses error : ' + error);
            return null;
          });

      }

      // chargement des articles issus du routage seulement au premier passage
      if (angular.isUndefined(SessionService.getSessionValue('articlesRoutage'))) {
        EspeceService.getInfoRoutage().then(function (response) {
          SessionService.setSessionValue('articlesRoutage', response.data);
        }).catch(function (error) {
          $log.log('Erreur lors de la recuperation des infos depuis le routage' + error);
        });
      }

      /**
       * afficher la modale d'erreur sur complement infos
       */
      function showErrorCplmtInfos() {
        $mdDialog.show({
          templateUrl: 'app/_common/template/alert.dialog.html',
          parent: angular.element($document.body),
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: true,
          multiple: true,
          controller: ErrorDialogController
        });
      }

      /**
       * sélection d'une adresse depuis le web
       *
       * @param address - adresse sélectionnée
       */
      vm.selectAddressWeb = function () {
        var address = {};
        for (var ia = 0; ia < vm.addresses.length; ia++) {
          if (vm.addresses[ia].checked) {
            address = vm.addresses[ia];
          }
        }

        // ENL1-RG03
        CommandeService.getClientCommandes(vm.personne.login, address.codjde, 1)
          .then(function (response) {
            var order = response.data[0];
            // ENL1-RG02
            vm.address = address;
            vm.address.complementInfos = vm.complementInfos;
            if (angular.isDefined(order)) {
              var date = moment(order.dateDemande.substring(0, 19), moment.ISO_8601);
              var lastDay = getDateFromLast2WorkDays();
              $log.log('date for 2 work days before : ' + lastDay);
              if (date.isBefore(lastDay)) {
                if (vm.complementInfos !== null && vm.complementInfos.length > 76) {
                  showErrorCplmtInfos();
                } else {
                  SessionService.setSessionValue('currentAddress', vm.address);
                  $state.go('article');
                }
              } else {
                $mdDialog.show({
                  templateUrl: 'app/_common/template/confirmOuiNon.dialog.html',
                  parent: angular.element($document.body),
                  scope: $scope,
                  preserveScope: true,
                  clickOutsideToClose: true,
                  locals: {
                    web: true
                  },
                  multiple: true,
                  controller: ConfirmDialogController
                });
              }
            } else {
              SessionService.setSessionValue('currentAddress', vm.address);
              $state.go('article');
            }
          })
          .catch(function (error) {
            $log.log('Get client commandes error : ' + error);
            return null;
          });
      };

      /**
       * sélection d'une adresse depuis le mobile
       *
       * @param address - adresse sélectionnée
       */
      vm.selectAddress = function (address) {
        // ENL1-RG03
        CommandeService.getClientCommandes(vm.personne.login, address.codejde, 1)
          .then(function (response) {
            var order = response.data[0];
            if (angular.isDefined(order)) {
              var date = moment(order.dateDemande.substring(0, 19), moment.ISO_8601);
              vm.address = address;
              var lastDay = getDateFromLast2WorkDays();
              $log.log('date for 2 work days before : ' + lastDay);
              if (date.isBefore(lastDay)) {
                // ENL1-RG02
                $rootScope.$broadcast('changeFooter', {
                  displayBack: true,
                  displayMenu: true,
                  displayNext: true,
                  displayExit: false
                });
                vm.addressState = 'validate';
              } else {
                $mdDialog.show({
                  templateUrl: 'app/_common/template/confirmOuiNon.dialog.html',
                  parent: angular.element($document.body),
                  scope: $scope,
                  preserveScope: true,
                  clickOutsideToClose: true,
                  locals: {
                    web: false
                  },
                  multiple: true,
                  controller: ConfirmDialogController
                });
              }
            } else {
              // ENL1-RG02
              vm.address = address;
              $rootScope.$broadcast('changeFooter', {
                displayBack: true,
                displayMenu: true,
                displayNext: true,
                displayExit: false
              });
              vm.addressState = 'validate';
            }
          })
          .catch(function (error) {
            $log.log('Get client commandes error : ' + error);
            return null;
          });
      };

      /**
       * Décochage des autres adresse si elles sont cochées
       *
       * @param address
       */
      vm.forceCheckAddress = function (address) {
        for (var ia = 0; ia < vm.addresses.length; ia++) {
          if (address.codjde === vm.addresses[ia].codjde) {
            vm.noAddressSelected = vm.addresses[ia].checked;
            vm.addresses[ia].checked = !vm.addresses[ia].checked;
          } else {
            vm.addresses[ia].checked = false;
          }
        }
      };

      /**
       * retour à l'écran home, depuis le web
       */
      vm.backToHome = function () {
        $state.go('home');
      };

      /**
       * valider l'adresse, aller vers article
       */
      $scope.$on('goToNext', function (event, data) {
        $log.log('event next received !! ' + data);
        if (vm.complementInfos.length > 76) {
          showErrorCplmtInfos();
        } else {
          vm.address.complementInfos = vm.complementInfos;
          SessionService.setSessionValue('currentAddress', vm.address);
          $rootScope.$broadcast('changeFooter', {
            displayBack: true,
            displayMenu: true,
            displayNext: false,
            displayExit: false
          });
          $state.go('articleMobile', {address: vm.address});
        }
      });

      /**
       * appui sur Back
       */
      $scope.$on('goToBack', function (event, data) {
        $log.log('event back received !! ' + data);
        if (vm.addressState === 'validate') {
          vm.addressState = 'select';
          $rootScope.$broadcast('changeFooter', {
            displayBack: true,
            displayMenu: true,
            displayNext: false,
            displayExit: false
          });
        } else {
          $rootScope.$broadcast('changeFooter', {
            displayBack: false,
            displayMenu: true,
            displayNext: true,
            displayExit: false
          });
          $state.go('homeMobile');
        }
      });

      /**
       * Controleur pour la modale d'erreur
       *
       * @param $scope
       * @param $mdDialog
       * @constructor
       */
      function ErrorDialogController($scope, $mdDialog) {
        var translate = $filter('translate');
        var that = $scope;
        that.messages = [translate('ERR008', {max: 76})];
        that.title = null;
        that.ok = null;
        that.closeDialog = function () {
          $mdDialog.hide();
        };
      }

      /**
       * Controleur pour la modale de confirmation
       *
       * @param $scope
       * @param $mdDialog
       * @param web
       * @constructor
       */
      function ConfirmDialogController($scope, $mdDialog, web) {
        var translate = $filter('translate');
        var that = $scope;
        that.messages = [translate('ERR009')];
        that.cancel = function () {
          $mdDialog.cancel();
        };
        that.confirm = function () {
          if (web) {
            SessionService.setSessionValue('currentAddress', vm.address);
            $mdDialog.hide();
            $state.go('article');
          } else {
            $rootScope.$broadcast('changeFooter', {
              displayBack: true,
              displayMenu: true,
              displayNext: true,
              displayExit: false
            });
            $mdDialog.hide();
            vm.addressState = 'validate';
          }
        };
      }

      /**
       * Get the list of bank holidays for a year
       *
       * @param year - number
       * @return Array of dates, in chronological order
       */
      function bankHolidays(year) {
        var jourAn = new Date(year, '00', '01');
        var feteTravail = new Date(year, '04', '01');
        var victoire1945 = new Date(year, '04', '08');
        var feteNationale = new Date(year, '06', '14');
        var assomption = new Date(year, '07', '15');
        var toussaint = new Date(year, '10', '01');
        var armistice = new Date(year, '10', '11');
        var noel = new Date(year, '11', '25');

        var g = year % 19;
        var c = Math.floor(year / 100);
        var h = (c - Math.floor(c / 4) - Math.floor(((8 * c) + 13) / 25) + (19 * g) + 15) % 30;
        var ih = h - (Math.floor(h / 28) * (1 - (Math.floor(h / 28) * Math.floor(29 / (h + 1)) * Math.floor((21 - g) / 11))));
        var j = (Number(year) + Math.floor(year / 4) + ih + 2 - c + Math.floor(c / 4)) % 7;
        var l = ih - j;
        var moisPaques = 3 + Math.floor((l + 40) / 44);
        var jourPaques = l + 28 - (31 * Math.floor(moisPaques / 4));
        var paques = new Date(year, moisPaques - 1, jourPaques);
        var vendrediSaint = new Date(year, moisPaques - 1, jourPaques - 2);
        var lundiPaques = new Date(year, moisPaques - 1, jourPaques + 1);
        var ascension = new Date(year, moisPaques - 1, jourPaques + 39);
        var pentecote = new Date(year, moisPaques - 1, jourPaques + 49);
        var lundiPentecote = new Date(year, moisPaques - 1, jourPaques + 50);

        return [jourAn, vendrediSaint, paques, lundiPaques, feteTravail, victoire1945, ascension, pentecote,
          lundiPentecote, feteNationale, assomption, toussaint, armistice, noel];
      }

      /**
       * Get date as a work day
       *
       * @param lastDay - date to analyse
       * @param holidays - list of bank holidays
       * @return moment
       */
      function getWorkDay(lastDay, holidays) {
        var lastDayDate = moment(lastDay.format('YYYY MM DD'));
        for (var i = holidays.length - 1; i >= 0; i--) {
          var holidayDate = moment(holidays[i]).format('YYYY MM DD');
          if (lastDayDate === holidayDate) {
            lastDay.subtract(1, 'days');
            if (lastDayDate.isoWeekday() === 7) {
              lastDay.subtract(2, 'days');
            } else if (lastDayDate.isoWeekday() === 6) {
              lastDay.subtract(1, 'days');
            }
            lastDayDate = lastDay.format('YYYY MM DD');
          }
        }
        return lastDay;
      }

      /**
       * Get Date before last 2 working days
       *
       * @return moment - date result
       */
      function getDateFromLast2WorkDays() {
        var lastDay = moment();
        var year = lastDay.year();
        var holidays = bankHolidays(year);

        lastDay.subtract(1, 'days');
        if (lastDay.year() !== year) {
          holidays = bankHolidays(lastDay.year());
        }
        lastDay = getWorkDay(lastDay, holidays);
        if (lastDay.year() !== year) {
          holidays = bankHolidays(lastDay.year());
        }

        lastDay.subtract(1, 'days');
        if (lastDay.year() !== year) {
          holidays = bankHolidays(lastDay.year());
        }

        return getWorkDay(lastDay, holidays);
      }

      init();

    });
})();
