(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    .controller('FlashInfoController', function ($rootScope, $scope, $log, $state, $translate, $stateParams) {

      var vm = this;
      vm.infos = $stateParams.infos;

      /**
       * aller vers la page suivante.
       */
      $scope.$on('goToNext', function (event, data) {
        $log.log('event next received !! ' + data);
        $rootScope.$broadcast('changeFooter', {
          displayBack: true,
          displayMenu: true,
          displayNext: false,
          displayExit: false
        });
        $state.go('adresseMobile');
      });

      /**
       * appui sur Back
       */
      $scope.$on('goToBack', function (event, data) {
        $log.log('event back received !! ' + data);
        $rootScope.$broadcast('changeFooter', {
          displayBack: false,
          displayMenu: true,
          displayNext: true,
          displayExit: false
        });
        $state.go('homeMobile');
      });

    });
})();
