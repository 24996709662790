(function () {
  angular
    .module('app')
    /* eslint max-params: "off" */
    /* global moment */
    .controller('HomeController', function ($rootScope, $scope, $log, $state, $filter, $mdDialog, $document, HomeService, SessionService, TechService, CommandeService, ClientService) {

      var vm = this;
      var LS_KEY_TOKEN = 'token';

      vm.homeState = 'home';
      vm.personne = null;
      if (angular.isDefined(SessionService.getValue(LS_KEY_TOKEN))) {
        vm.personne = SessionService.getValue(LS_KEY_TOKEN).personne;
      } else if (angular.isDefined(SessionService.getSessionValue(LS_KEY_TOKEN))) {
        vm.personne = SessionService.getSessionValue(LS_KEY_TOKEN).personne;
      }
      vm.paymentStatus = SessionService.getSessionValue('payment');
      if (angular.isUndefined(vm.paymentStatus)) {
        vm.paymentStatus = SessionService.getValue('payment');
        SessionService.setValue('payment', undefined);
      }
      vm.order = SessionService.getValue('currentOrderId');
      if (angular.isDefined(vm.order)) {
        SessionService.setValue('currentOrderId', undefined);
      } else {
        vm.order = SessionService.getValue('currentOrder');
      }
      if (angular.isDefined(SessionService.getValue('web'))) {
        SessionService.setSessionValue('web', SessionService.getValue('web'));
      }
      vm.lastOrders = [];
      vm.waitDialog = undefined;
      vm.hasValidAddress = false;
      $rootScope.$broadcast('changeFooter', {
        displayBack: false,
        displayMenu: false,
        displayNext: false,
        displayExit: false
      });

      function init() {
        ClientService.getAddresses(vm.personne.login)
          .then(function (response) {
            $log.log('Get client addresses : ' + response);
            vm.hasValidAddress = true;
            $rootScope.$broadcast('changeFooter', {
              displayBack: false,
              displayMenu: false,
              displayNext: true,
              displayExit: false
            });
          })
          .catch(function (error) {
            $log.log('Get client addresses error : ' + error);
            return null;
          });
      }

        /**
       * Infos pour le flash info
       */
      vm.infos = HomeService.getFlashInfo()
        .then(function (response) {
          vm.infos = response.data;
        })
        .catch(function (error) {
          $log.log('Erreur pendant récupération flash info : ' + error);
          showErrorDialog(null);
        });

      // afficher bouton logout
      $rootScope.$broadcast('showLogout', {
        displayLogout: true
      });

      /**
       * récupérer les infos du client
       */
      HomeService.getClient(vm.personne.login).then(function (response) {
          SessionService.setSessionValue('client', response.data);
        })
        .catch(function (error) {
          $log.log('Erreur pendant récupération client');
          if (error.data.message === 'Aucun client trouvé') {
            vm.hasValidAddress = false;
            $rootScope.$broadcast('changeFooter', {
              displayBack: false,
              displayMenu: false,
              displayNext: false,
              displayExit: false
            });
            showErrorDialog('MSG020', false, 'nodisplay');
            SessionService.setSessionValue(LS_KEY_TOKEN, undefined);
          } else {
            showErrorDialog(null);
          }
      });

      /**
       * récupérer la conf depuis le serveur
       */
      TechService.getConf().then(function (response) {
        SessionService.setSessionValue('conf', response.data);
      })
        .catch(function (error) {
          $log.log('Erreur pendant récupération conf : ' + error);
          showErrorDialog(null);
      });

      function showErrorDialog(message, clickOutside, okButton) {
        var trans = $filter('translate');
        var msg = 'ADV121';
        var outsideToClose = true;
        if (angular.isDefined(clickOutside)) {
          outsideToClose = clickOutside;
        }
        var okMessage = trans('ADV102');
        if (angular.isDefined(okButton)) {
          okMessage = okButton;
        }
        if (message !== null) {
          msg = message;
        }
        $mdDialog.show({
          templateUrl: 'app/_common/template/alert.dialog.html',
          parent: angular.element($document.body),
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: outsideToClose,
          escapeToClose: outsideToClose,
          locals: {
            title: trans('ADV120'),
            messages: [trans(msg)],
            ok: okMessage,
            web: SessionService.getSessionValue('web').desktop
          },
          multiple: true,
          controller: OrderDialogController
        });
      }

      /**
       * Affichage de l'alert de confirmation d'annulation de demande si présence en session (et à TRUE)
       */
      if (angular.isDefined(SessionService.getSessionValue('cancelOrder')) &&
        SessionService.getSessionValue('cancelOrder')) {
        var translate = $filter('translate');
        $mdDialog.show({
          templateUrl: 'app/_common/template/alert.dialog.html',
          parent: angular.element($document.body),
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: true,
          locals: {
            title: translate('ADV106'),
            messages: [translate('ADV107')]
          },
          multiple: true,
          controller: AlertController
        });
        SessionService.setSessionValue('cancelOrder', false);
        cleanSession();
      }

      function saveWaitingOrder() {
        var trans = $filter('translate');

        var message = 'Enregistrement de la commande en cours...';
        vm.waitDialog = $mdDialog.show({
          templateUrl: 'app/_common/template/wait.dialog.html',
          parent: angular.element($document.body),
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: false,
          locals: {
            messages: [message]
          },
          multiple: true,
          controller: WaitDialogController
        });
        CommandeService.validerCommandeAttente(vm.order, vm.paymentStatus.status).then(function (response) {
          $log.log('Commande enregistrée');
          vm.getOrders();
          vm.nbOrder = response.data.reference;
          $mdDialog.hide(vm.waitDialog, 'finished');

          if (angular.isDefined(vm.paymentStatus) && vm.paymentStatus.status === 'OK') {

            var listeMessage = [trans('ADV100') + vm.nbOrder.trim() + '.', trans('ADV101')];

            if (response.data.enlevementExeptionel) {
              listeMessage.push(trans('MSG034'));
            }

            $mdDialog.show({
              templateUrl: 'app/_common/template/alert.dialog.html',
              parent: angular.element($document.body),
              scope: $scope,
              preserveScope: true,
              clickOutsideToClose: true,
              locals: {
                title: trans('ADV099'),
                messages: listeMessage,
                ok: trans('ADV102'),
                web: SessionService.getSessionValue('web').desktop
              },
              multiple: true,
              controller: OrderDialogController
            });
          } else {
            $mdDialog.show({
              templateUrl: 'app/_common/template/alert.dialog.html',
              parent: angular.element($document.body),
              scope: $scope,
              preserveScope: true,
              clickOutsideToClose: true,
              locals: {
                title: trans('ADV117'),
                messages: [trans('ADV118'), trans('ADV122'), trans('ADV123')],
                ok: trans('ADV102'),
                web: SessionService.getSessionValue('web').desktop
              },
              multiple: true,
              controller: OrderDialogController
            });
          }
          cleanSession();

        })
        .catch(function (error) {
          $mdDialog.hide(vm.waitDialog, 'finished');
          $log.log('Erreur lors de l enregistrement de la commande : ' + error);

          if (error.data.message === 'Aucun article gratuit') {
            $log.log('Paiement refusé, pas de finalisation de commande');

            var trans = $filter('translate');
            $mdDialog.show({
              templateUrl: 'app/_common/template/alert.dialog.html',
              parent: angular.element($document.body),
              scope: $scope,
              preserveScope: true,
              clickOutsideToClose: true,
              locals: {
                title: trans('ADV117'),
                messages: ['Veuillez réessayer plus tard ou vous mettre en relation avec qui de droit.'],
                ok: trans('ADV102'),
                web: SessionService.getSessionValue('web').desktop
              },
              multiple: true,
              controller: OrderDialogController
            });
          } else {
            showErrorDialog(null);
          }
          cleanSession();
        });
      }

      function saveOrder() {
        CommandeService.enregistrerCommande(vm.order.order).then(function (response) {
          var trans = $filter('translate');
          $log.log('Commande enregistrée');

          vm.getOrders();
          vm.nbOrder = response.data.reference;

          var listeMessage = [trans('ADV100') + vm.nbOrder.trim() + '.', trans('ADV101')];

          if (response.data.enlevementExeptionel) {
            listeMessage.push(trans('MSG034'));
          }

          $mdDialog.hide(vm.waitDialog, 'finished');
          $mdDialog.show({
            templateUrl: 'app/_common/template/alert.dialog.html',
            parent: angular.element($document.body),
            scope: $scope,
            preserveScope: true,
            clickOutsideToClose: true,
            locals: {
              title: trans('ADV099'),
              messages: listeMessage,
              ok: trans('ADV102'),
              web: SessionService.getSessionValue('web').desktop
            },
            multiple: true,
            controller: OrderDialogController
          });
          cleanSession();
        })
          .catch(function (error) {
            $log.log('Erreur lors de l enregistrement de la commande : ' + error);
            cleanSession();
            showErrorDialog(null);
          });
      }

      /**
       * Gestion du retour de paiement (si présent)
       */
      if (angular.isDefined(vm.paymentStatus)) {
        saveWaitingOrder();
      } else if (angular.isDefined(vm.order) || !angular.isUndefined(vm.order)) {
        vm.waitDialog = $mdDialog.show({
          templateUrl: 'app/_common/template/wait.dialog.html',
          parent: angular.element($document.body),
          scope: $scope,
          preserveScope: true,
          clickOutsideToClose: false,
          locals: {
            messages: ['Enregistrement de la commande en cours...']
          },
          multiple: true,
          controller: WaitDialogController
        });

        if (SessionService.getSessionValue('clientInfoUpdate')) {
          ClientService.updateClient(vm.personne.login, vm.order.client).then(function (response) {
            $log.log('MAJ client ' + response.data);
            ClientService.updateAdresse(vm.personne.login, vm.order.address).then(function (response) {
              $log.log('MAJ adresse : ' + response.data);
              saveOrder();
            })
              .catch(function (error) {
                $log.log('Erreur lors de la mj de adresse : ' + error);
                showErrorDialog(null);
                cleanSession();
              });
          })
            .catch(function (error) {
              $log.log('Erreur lors de la mj de client : ' + error);
              showErrorDialog(null);
              cleanSession();
            });
        } else {
          if (angular.isDefined(vm.currentOrder)) {
            vm.currentOrder.modifClient = false;
          }
          $log.log('Enregistrement commande ');
          saveOrder();
        }
      }

      /**
       * récupérer les dernières commandes pour afficher la dernière demande effectuée
       */
      vm.getOrders = function () {
        // ACC-RG02
        HomeService.getClientOrders(vm.personne.login, 3)
          .then(function (response) {
            vm.lastOrders = [];
            vm.orders = response.data;
            if (vm.orders.length > 0) {
              // maximum 3 dernières demandes
              var max = (vm.orders.length > 3) ? 3 : vm.orders.length;
              for (var i = 0; i < max; i++) {
                var lastOrder = vm.orders[i];
                var date = moment(lastOrder.dateDemande.substring(0, 19), moment.ISO_8601);
                vm.lastOrders.push({
                  date: moment(date).format('DD/MM/YYYY') + ' à ' + moment(date).format('H') + 'h' + moment(date).format('mm'),
                  addressName: lastOrder.adresse.nom,
                  address: lastOrder.adresse.adresse1.trim() + ' ' + lastOrder.adresse.adresse2.trim(),
                  addressTown: lastOrder.adresse.codePostal + ' ' + lastOrder.adresse.commune,
                  ref: lastOrder.reference,
                  canal: lastOrder.canal,
                  status: lastOrder.statut
                });
              }
            }
          })
          .catch(function (error) {
            $log.log('Get client commandes error : ' + error);
            return null;
          });
      };

      vm.orders = [vm.getOrders()];

      /**
       * bouton + d'infos (mobile)
       */
      vm.moreInfo = function () {
        $rootScope.$broadcast('changeFooter', {
          displayBack: true,
          displayMenu: false,
          displayNext: false,
          displayExit: false
        });
        vm.homeState = 'info';
      };

      /**
       * aller vers la page adresse
       */
      vm.goToAddress = function () {
        $rootScope.$broadcast('changeHeader', {
          displayCancel: true
        });
        $rootScope.$broadcast('showLogout', {
          displayLogout: false
        });
        $state.go('adresse');
      };

      /**
       * aller vers la page suivante.
       * a déterminer suivant s'il y a un flash info ou non.
       */
      $scope.$on('goToNext', function (event, data) {
        $log.log('event next received !! ' + data);
        $rootScope.$broadcast('changeHeader', {
          displayCancel: true
        });
        cleanSession();
        // ACC-RG01
        if (angular.equals(vm.infos, {}) || vm.infos.length === 0) {
          $rootScope.$broadcast('changeFooter', {
            displayBack: true,
            displayMenu: true,
            displayNext: false,
            displayExit: false
          });
          $state.go('adresseMobile');
        } else {
          $state.go('flashInfoMobile', {infos: vm.infos});
        }
      });

      /**
       * appui sur Back
       */
      $scope.$on('goToBack', function (event, data) {
        $log.log('event back received !! ' + data);
        if (vm.homeState === 'info') {
          $rootScope.$broadcast('changeFooter', {
            displayBack: false,
            displayMenu: true,
            displayNext: true,
            displayExit: false
          });
          vm.homeState = 'home';
        }
      });

      /**
       * Controleur pour la confirmation d'annulation
       *
       * @param $scope
       * @param $mdDialog
       * @param messages
       * @constructor
       */
      function AlertController($scope, $mdDialog, messages, title) {
        var that = $scope;
        that.messages = messages;
        that.title = title;
        that.ok = null;
        that.closeDialog = function () {
          $mdDialog.hide();
        };
      }

      /**
       * Controleur pour la modale de confirmation de demande
       *
       * @param $scope
       * @param $mdDialog
       * @param messages
       * @param title
       * @param ok
       * @param web
       * @param SessionService
       * @constructor
       */
      function OrderDialogController($scope, $mdDialog, SessionService, messages, title, ok, web) {
        var that = $scope;
        that.messages = messages;
        that.title = title;
        that.ok = ok;
        that.closeDialog = function () {
          cleanSession();
          $mdDialog.hide();
          if (web) {
            $state.go('home');
          } else {
            $rootScope.$broadcast('changeFooter', {
              displayBack: false,
              displayMenu: true,
              displayNext: true,
              displayExit: false
            });
            $state.go('homeMobile');
          }
          SessionService.setSessionValue('order', undefined);
        };
      }

      /**
       * Controleur pour la modale d'attente d'enregistrement
       *
       * @param $scope
       * @param $mdDialog
       * @param messages
       * @constructor
       */
      function WaitDialogController($scope, $mdDialog, messages) {
        var that = $scope;
        that.messages = messages;
        that.closeDialog = function () {
          $mdDialog.hide();
        };
      }

      function cleanSession() {
        SessionService.setSessionValue('currentOrderId', undefined);
        SessionService.setSessionValue('currentOrder', undefined);
        SessionService.setValue('currentOrderId', undefined);
        SessionService.setValue('currentOrder', undefined);
        SessionService.setSessionValue('currentAddress', undefined);
        SessionService.setValue('payment', undefined);
        vm.paymentStatus = undefined;
        vm.order = undefined;
      }

      init();

    });
})();
