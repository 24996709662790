(function () {
  angular
    .module('app').config(translationConfig);

  function translationConfig($translateProvider) {
    $translateProvider.useStaticFilesLoader({
      prefix: 'app/locale-',
      suffix: '.json'
    });
    $translateProvider.preferredLanguage('fr');
    $translateProvider.useSanitizeValueStrategy('escape');
  }
})();
